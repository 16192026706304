import Vue from 'vue'
import Router from 'vue-router'

// 获取原型对象push函数
const originalPush = Router.prototype.push

// 获取原型对象replace函数
const originalReplace = Router.prototype.replace

// 修改原型对象中的push函数
Router.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

import IndexPage from '@/pages/index/indexPage.vue'

import Introduction from '@/pages/companypart/introduction'
import Course from '@/pages/companypart/course'
import Certificate from '@/pages/companypart/certificate'
import Culture from '@/pages/companypart/culture'
import Team from '@/pages/companypart/team'
import Service from '@/pages/companypart/service'
import Product from '@/pages/productpart/product'
import ProductDetail from '@/pages/productpart/productDetail'


import News from '@/pages/newspart/news'
import NewsDetail from '@/pages/newspart/newsDetail'

import Source from '@/pages/sourcepart/source'
import Social from '@/pages/sourcepart/social'
import Campus from '@/pages/sourcepart/campus'
import Man from '@/pages/sourcepart/man'

import Contact from '@/pages/telpart/contact'
import Message from '@/pages/telpart/message'

Vue.use(Router);
 
export default new Router({
  mode: 'history',
  routes: [
    // 首页
    {
      path : '/',
      name : 'IndexPage',
      component: IndexPage
    },


    // 公司概况
    {
      path : '/introduction',
      name : 'introduction',
      component: Introduction
    },
    // 发展历程
    {
      path : '/course',
      name : 'course',
      component: Course
    },
    // 荣誉证书
    {
      path : '/certificate',
      name : 'certificate',
      component: Certificate
    },
    // 企业文化
    {
      path : '/culture',
      name : 'culture',
      component: Culture
    },
    {
      path : '/service',
      name : 'service',
      component: Service
    },
    //团队介绍
    {
      path : '/team',
      name : 'team',
      component: Team
    },

    // 产品
    {
      path : '/product',
      name : 'product',
      component: Product
    },
    {
      path : '/productDetail',
      name : 'productDetail',
      component: ProductDetail
    },


    // 新闻
    {
      path : '/news',
      name : 'news',
      component: News
    },
    {
      path : '/newsDetail',
      name : 'newsDetail',
      component: NewsDetail
    },



    // 人才理念
    {
      path : '/source',
      name : 'source',
      component: Source
    },
    // 社会招聘
    {
      path : '/social',
      name : 'social',
      component: Social
    },
    // 校园招聘
    {
      path : '/campus',
      name : 'campus',
      component: Campus
    },
    // 员工风采
    {
      path : '/man',
      name : 'man',
      component: Man
    },


    // 联系方式
    {
      path : '/contact',
      name : 'contact',
      component: Contact
    },
    // 在线留言
    {
      path : '/message',
      name : 'message',
      component: Message
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})