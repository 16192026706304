<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_resources_talent')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <template  v-if="$store.state.deviceType === '1'">
      <div class="contentBox">
      <div class="content wow fadeInUp">
        <h3 class="title">{{ $t("common.base2.ideas") }}</h3>
        <p v-for="(item, index) in $t('common.base2.list1')" :key="index + 'x'">{{ item }}</p>
        <div style="width: 100%; display: flex; justify-content: center">
          <div class="line"></div>
        </div>
        <p v-for="(item, index) in $t('common.base2.list6')" :key="index">{{ item }}</p>
      </div>
    </div>
    <div class="imgBox">
      <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea_bg.png" class="image" /> -->
      <div class="contentBox pic wow fadeInUp">
        <!-- <div class="title_box">
          <div class="main">{{ $t("common.base2.mainLocation") }}</div>
        <div class="mainLine"></div>
        </div> -->

        <div class="items">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea1.png" />
          <div class="title">{{ $t("common.base2.ideaTitle1") }}</div>
          <div class="centerBox"><div class="line"></div></div>
          <div class="desc">{{ $t("common.base2.ideaDesc1") }}</div>
        </div>
        <div class="items">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea2.png" />
          <div class="title">{{ $t("common.base2.ideaTitle2") }}</div>
          <div class="centerBox"><div class="line"></div></div>
          <div class="desc">{{ $t("common.base2.ideaDesc2") }}</div>
        </div>
        <div class="items">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea3.png" />
          <div class="title">{{ $t("common.base2.ideaTitle3") }}</div>
          <div class="centerBox"><div class="line"></div></div>
          <div class="desc">{{ $t("common.base2.ideaDesc3") }}</div>
        </div>
      </div>
    </div>
    </template>
    <template v-else>
      <div class="phoneContent">
        <div class="pageTitle">
          {{$t('common.base2.list1')[0]}}
        </div>
        <p class="pContent">
          {{$t('common.base2.list6').join()}}
        </p>
        <div class="picBox">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea1.png" />
        </div>
        <p class="pTitle">
          {{ $t("common.base2.ideaTitle1") }}
        </p>
        <p class="pContent borderBottom">
          {{$t("common.base2.ideaDesc1")}}
        </p>
        <div class="picBox">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea2.png" />
        </div>
        <p class="pTitle">
          {{ $t("common.base2.ideaTitle2") }}
        </p>
        <p class="pContent borderBottom">
          {{$t("common.base2.ideaDesc2")}}
        </p>
        <div class="picBox">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/idea3.png" />
        </div>
        <p class="pTitle">
          {{ $t("common.base2.ideaTitle3") }}
        </p>
        <p class="pContent">
          {{$t("common.base2.ideaDesc3")}}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/source",
    };
  },
  methods: {
    goClass() {},
  },
};
</script>

<style scoped lang="scss">
.imgBox {
  width: 100%;
  height: 356px;
  margin: .7rem 0 1.5rem 0;
  position: relative;

  .image {
    display: block;
    width: 100%;
    height: 100%;
  }
  .pic {
    // margin-top: -183px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .title_box{
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      
      .main {
      font-size: 22px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .mainLine {
      width: 72px;
      height: 7px;
      background: #ffffff;
      border-radius: 7px;
      margin-left: 10px;
    }

    }

    .items {
      width: 31%;
      text-align: center;
      background: #f9f9f9;
      img {
        display: block;
        width: 100%;
        height: 252px;
      }
      .centerBox {
        width: 100%; 
        display: flex; 
        justify-content: center
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #262830;
        margin: 26px 0 16px 0;
      }
      .line {
        width: 35px;
        height: 5px;
        background: #19cc41;
        border-radius: 7px;
        display: flex;
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: #717171;
        line-height: 25px;
        margin: 9px 30px 38px 30px;
      }
    }
  }
}
.content {
  text-align: center;
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #262830;
    margin: 48px 0 20px 0;
  }
  .line {
    width: 67px;
    height: 5px;
    background: #19cc41;
    border-radius: 7px;
    display: flex;
    margin: 30px 0;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #7a7a7a;
    line-height: 26px;
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .imgBox {
    margin-bottom: 1200px;
  }
  .imgBox .pic .items {
    width: initial;
    flex: auto;
  }
}
.phoneContent {
  padding: .34rem .4rem .46rem;
  font-size: .24rem;
  .pageTitle {
    color: #262830;
    font-weight: 800;
    font-size: .28rem;
    line-height: .42rem;
    margin-bottom: .28rem;
  }
  .pContent {
    color: #717171;
    line-height: .36rem;
    font-weight: 500;
  }
  .picBox {
    margin: .48rem 0;
    img {
      width: 100%;
    }
  }
  .pTitle {
    color: #262830;
    font-weight: 800;
    font-size: .34rem;
    margin-bottom: .24rem;
  }
  .borderBottom {
    padding-bottom: .44rem;
    border-bottom: 1px solid #EEEEEE;
  }
}
</style>
