<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_intoduction')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="company-msg contentBox">
      <div class="company-introduce wow fadeInLeft">
        <div class="title">{{ $t("pages.main.shengli_t1") }}{{ $t("pages.main.shengli_t2") }}</div>
        <p v-for="(item, ind) in pList" :key="ind" :class="ind == 1 ? 'margin' : ''">{{ item.name }}</p>
        <div class="num">
          <div class="num_item">
            <div class="count">{{num1}}<span class="add_icon">+</span></div>
            <span class="txt">{{ $t("pages.main.shengli_notice1") }}</span>
          </div>
          <div class="num_item">
            <div class="count">{{num2}}<span class="add_icon">+</span></div>
            <span class="txt">{{ $t("pages.main.shengli_notice2") }}</span>
          </div>
          <div class="num_item">
            <div class="count">{{num3}}<span class="add_icon">+</span></div>
            <span class="txt">{{ $t("pages.main.shengli_notice3") }}</span>
          </div>
          <div class="num_item">
            <div class="count">{{num4}}<span class="add_icon">+</span></div>
            <span class="txt">{{ $t("pages.main.shengli_notice4") }}</span>
          </div>
        </div>
      </div>
      <div class="company-msg-picb wow fadeInRight">
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companybg.jpg" alt="" />
      </div>
    </div>
    <div class="contentBox bases wow fadeInUp">
      <div class="title">{{ $t("pages.main.shengli_t3") }}</div>
      <div class="txt_box">
        <div class="base_box">
          <div><span class="icon"></span>{{ $t("pages.main.base1") }}</div>
          <div class="txt">{{ $t("pages.main.base2") }}</div>
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_jianghua.png" />
        </div>
        <div class="base_box">
          <div><span class="icon"></span>{{ $t("pages.main.base3") }}</div>
          <div class="txt">{{ $t("pages.main.base4") }}</div>
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_linwu.png" class="img1"/>

        </div>
        <div class="base_box" style="margin-right: 0;">
          <div><span class="icon"></span>{{ $t("pages.main.base5") }}</div>
          <div class="txt">{{ $t("pages.main.base6") }}</div>
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_donguan.png" class="img1"/>

        </div>
      </div>
    </div>
    <!-- <div class="contentBox img_box">
      <div style="width: 60%; margin-right: 0.2rem;">
      <div class="img_left">
        <div class="left_top">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base_start.png" />
          <span class="title">{{ $t("pages.main.base1") }}</span>
        </div>
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base1.png" class="img1"/>
      </div>
      <div class="img_bottom">
        <div class="left_bottom">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base_start.png" />
          <span class="title">{{ $t("pages.main.base3") }}</span>
        </div>
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base2.png" class="img2"/>

      </div>
    </div>
      <div class="right_box">
        <div class="right">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base_start.png" />
          <span class="title">{{ $t("pages.main.base5") }}</span>
        </div>
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/base3.png"/>
      </div>
    </div> -->
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/introduction",
      num1: 20,
      num2: 88000,
      num3: 100,
      num4: 3800,
    };
  },
  computed: {
    pList() {
      return [
        {
          name: this.$t("pages.company.companyp1"),
        },
      ];
    },
  },
  mounted() {
    this.sortNum(0,20,'num1')
    this.sortNum(87900,88000,'num2')
    this.sortNum(50,100,'num3')
    this.sortNum(3700,3800,'num4')
  },
  methods: {
    goClass() {},
    sortNum(baseNumber, finalNum, str) {
      let step = (finalNum - baseNumber) / 50;//颗粒化可以控制速度
	      let count = baseNumber; // 计数器
	      let initial = 0;
	      let timer = setInterval(() => {
	        count = count + step;
	        if (count >= finalNum) {
	          clearInterval(timer);
	          count = finalNum;
	        }
	        let t = count.toFixed(0);
	        initial = t;
	        this[str] = initial;
	      }, 30);
    }
  },
};
</script>

<style scoped lang="scss">
.img_box {
  display: flex;
  .img_left,.img_bottom {
    position: relative;
    width: 100%;
    .left_top,
    .left_bottom {
      position: absolute;
      display: none;
      top: -0.58rem;
      left: .19rem;
      height: .3rem;
      .title {
        font-size: 0.24rem;
        color: #FFFFFF;
      }
      img {
        width: 0.19rem;
        height: 0.18rem;
        margin-right: .13rem;
      }
    }
    img {
      height: 3.78rem;
      width: 100%;
    }
  }
  .img_left:hover{
    .left_top{
      display: block;
    }
  }
  .img_bottom:hover{
    .left_bottom{
      display: block;
    }
  }

  .right_box {
    position: relative;
    width: 40%;
    .right {
      position: absolute;
      top: -0.58rem;
      left: .19rem;
      display: none;
      .title {
        font-size: 0.24rem;
        color: #FFFFFF;
      }
      img {
        width: 0.19rem;
        height: 0.18rem;
        margin-right: .13rem;
      }
    }
    img {
      height: 7.8rem;
      width: 100%;
    }

  }
  .right_box:hover{
    .right{
      display: block;
    }
  }
}
.bases {
  .title {
    font-weight: bold;
    font-size: 0.4rem;
    color: #262830;
  }
  .txt_box {
    display: flex;
    margin: 0.34rem 0;
    .base_box {
      font-weight: 800;
      font-size: 0.2rem;
      color: #262830;
      margin-right: .4rem;
      width: 33%;

      .icon {
        width: 0.06rem;
        height: 0.16rem;
        background: #1fce76;
        display: inline-block;
        margin-right: 0.1rem;
      }
      .txt{
        margin-left: .16rem;
      }
      img{
        width: 100%;
        height: auto;
        margin-top: .25rem;
        transition: transform 0.3s ease;
      }
      img:hover {
            transform: scale(1.1); /* 放大1.1倍 */
      }
    }
  }
}
.company-introduce {
  width: 6.86rem;
}
.num {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.6rem;
  flex-wrap: wrap;
  .num_item {
    font-size: 0.42rem;
    font-weight: bold;
    color: #1fce76;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;

    .count {
      position: relative;
      .add_icon {
        position: absolute;
        top: -0.13rem;
        right: -0.23rem;
        font-weight: 400;
        color: #1fce76;
        font-size: .3rem;
      }
    }
    .txt {
      font-size: .2rem;
      font-weight: 800;
      color: #262830;
      display: block;
    }
  }
}
.margin {
  margin-bottom: .16rem !important;
}
.company-msg {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  p {
    font-size: .14rem;
    font-weight: 400;
    color: #717171;
    line-height: .22rem;
    margin-bottom: 0.08rem;
  }
  .title {
    font-size: .36rem;
    font-weight: 600;
    color: #262830;
    line-height: .5rem;
    margin: 0.2rem 0 16px 0;
  }
  .company-msg-picb {
    img {
      width: 100%;
      height: 3.34rem;
      object-fit: cover;
      display: block;
      margin-top: 0.2rem;
    }
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .company-introduce{
    p{
      font-size: 0.32rem;
      line-height: 0.48rem;
      color: #1B1B1D;
    }
  }
}
</style>
