<template>
  <div>
    <div :class="$store.state.deviceType === '2' ? 'phonePicBox' : ''"
    class="top-picbox">
      <div v-if="name.indexOf('product') > -1">
        <span class="title">{{ proText }}</span>
        <span class="subTitle">{{ proSubText }}</span>
      </div>
      <div v-else-if="name.indexOf('news') > -1">
        <span class="title">{{ proText }}</span>
        <span class="subTitle">{{ proSubText }}</span>
      </div>
      <div v-else>
        <span class="title">{{ text }}</span>
        <span class="subTitle">{{ subText }}</span>
      </div>
      <img :src="picList[picIndex]" alt="" />
    </div>
    <div :class="$store.state.deviceType !== '2' ? 'top-positionbg' : 'noBorder'">
      <div class="top-position contentBox" v-if="curList && curList.children && curList.children.length">
        <div class="leftNavBar" v-if="$store.state.deviceType === '1'">
          <div v-for="(item,index) in navBarList" 
          @click="toNavBarPage(item)"
          :style="{'cursor' : index !==  navBarList.length - 1 ? 'pointer' : ''}"
          :key="index" class="navbarItem">
            {{item.name}}<span v-if="index !==  navBarList.length - 1">></span>
          </div>
        </div>
        <div :class="$store.state.deviceType !== '2' ? 'top-position-r' : 'phoneClass'"
        >
          <div v-for="(item, ind) in curList.children" :key="ind" 
          class="top-position-r-item"
            @click="gopage(item, ind)">
            <div class="txt" :class="cur_index == ind ? 'active' : ''">{{ item.name }}</div>
            <div class="rightLine" v-show="ind !== curList.children.length - 1"></div>
            <!-- <img v-show="$store.state.deviceType === '1'"
            src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/triangle.png" class="img"
              :style="{ opacity: cur_index == ind && $store.state.deviceType === '1' ? 1 : 0 }" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { categoryList } from "@/utils/api";
export default {
  props: {
    name: String,
    text: String,
    subText: String,
  },
  data() {
    return {
      cur_index: 0,
      proText: "",
      picIndex: 0,
      picList: ["https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_fazhanbanner.png", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_chanpbanner.png", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_zixunbanner.png", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_renciabanner.png", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_lianxibanner.png"],
      pronavList: [],
      proSubText: "",
      categoryLists: [],
      navBarList: [
        {url: '/',name: this.$t("common.base.nav_index")}
      ]
    };
  },
  computed: {
    // 总导航
    navList() {
      return [
        {
          // name: '关于盛利',
          name: this.$t("common.base.nav_aboutus"),
          url: "/introduction",
          children: [
            {
              name: this.$t("common.base.nav_aboutus_intoduction"),
              url: "/introduction",
            },
            {
              name: this.$t("common.base.nav_aboutus_course"),
              url: "/course",
            },
            {
              name: this.$t("common.base.nav_aboutus_certificate"),
              url: "/certificate",
            },
            {
              name: this.$t("common.base.nav_aboutus_culture"),
              url: "/culture",
            },
            {
              name: this.$t('common.base.nav_aboutus_service'),
              url: '/service',
            },
            {
              name: this.$t("common.base.nav_aboutus_team"),
              url: "/team",
            }


          ],
        },
        {
          name: this.$t("common.base.nav_products"),
          url: "/product?type=0",
          children: this.categoryLists
        },
        {
          name: this.$t("common.base.nav_news"),
          url: "/news?type=0",
          children: [
            {
              name: this.$t("common.base.nav_news_companynews"),
              url: "/news?type=1",
            },
            {
              name: this.$t("common.base.nav_news_technical"),
              url: "/news?type=2",
            },
            {
              name: this.$t("common.base.nav_news_problem"),
              url: "/news?type=3",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_resources"),
          url: "/source",
          children: [
            {
              name: this.$t("common.base.nav_resources_talent"),
              url: "/source",
            },
            {
              name: this.$t("common.base.nav_resources_social"),
              url: "/social",
            },
            {
              name: this.$t("common.base.nav_resources_campus"),
              url: "/campus",
            },
            {
              name: this.$t("common.base.nav_resources_staff"),
              url: "/man",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_contact"),
          url: "/contact",
          children: [
            {
              name: this.$t("common.base.nav_contact_contact"),
              url: "/contact",
            },
            {
              name: this.$t("common.base.nav_contact_message"),
              url: "/message",
            },
          ],
        },
      ]
    },
    // 当前导航
    curList() {
      // return this.navList[0]
      const query = qs.parse(this.name.split("?").pop());
      // console.log("执行666");
      let cur = {};
      let arr = this.navList;
      let newIndex = 0
      arr.forEach((item, index) => {
        if (item.url == "/news?type=0") {
          newIndex = index
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach((items, ind) => {
            //console.log(items,'0000000')
            let pathname = items.url.split("?").shift();
            let type = qs.parse(items.url.split("?").pop()).type;
            if (this.name.split("?").shift() === pathname && query.type === type) {
              this.cur_index = ind;
              cur = item;
              this.picIndex = index;
            }
          });
        }
      });
      // console.log('--------name-------', this.name,arr,this.cur_index)
      if (this.name.startsWith("/detail")) {
        this.cur_index = "";
      }

      if (this.name.startsWith("/product") && query.type === "0") {
        this.picIndex = 1;
        cur = arr[1];
        this.cur_index = "";
        this.proText = this.$t("common.base.nav_products");
      }
      if (this.name.startsWith("/product") && query.type === "1") {
        this.proText = this.$t("common.base.nav_products_cell");
        this.proSubText = this.$t("common.base.nav_proSubText1");
      }
      if (this.name.startsWith("/product") && query.type === "2") {
        this.proText = this.$t("common.base.nav_products_storage");
        this.proSubText = this.$t("common.base.nav_proSubText2");
      }
      if (this.name.startsWith("/product") && query.type === "3") {
        this.proText = this.$t("common.base.nav_products_vehicle");
        this.proSubText = this.$t("common.base.nav_proSubText3");
      }
      if (this.name.startsWith("/product") && query.type === "4") {
        this.proText = this.$t("common.base.nav_products_custom");
        this.proSubText = this.$t("common.base.nav_proSubText4");
      }
      if (this.name.startsWith("/product") && query.type === "5") {
        this.proText = this.$t("common.base.nav_products_daily");
        this.proSubText = this.$t("common.base.nav_proSubText7");
        
      }
      if (this.name.startsWith("/product") && query.type === "6") {
        this.proText = this.$t("common.base.nav_products_hdg");
        this.proSubText = this.$t("common.base.nav_proSubText6");
      }
      if (this.name.startsWith("/product") && query.type === "7") {
        this.proText = this.$t("common.base.nav_products_ddc");
        this.proSubText = this.$t("common.base.nav_proSubText5");
      }
      if (this.name.startsWith("/news") && query.type === "0") {
        this.picIndex = 2;
        cur = arr[2];
        // this.cur_index = "";
        this.proText = this.$t("common.base.nav_news_problem");
        this.proSubText = this.$t("common.base.nav_news_sub");
        this.cur_index = 2
      }
      if (this.name.startsWith("/news") && query.type === "1") {
        this.proText = this.$t("common.base.nav_news_companynews");
        this.proSubText = this.$t("common.base.nav_news_sub");
      }
      if (this.name.startsWith("/news") && query.type === "2") {
        this.proText = this.$t("common.base.nav_news_technical");
        this.proSubText = this.$t("common.base.nav_news_sub");
      }
      if (this.name.startsWith("/news") && query.type === "3") {
        this.proText = this.$t("common.base.nav_news_problem");
        this.proSubText = this.$t("common.base.nav_news_sub");
      }
      if (this.$route.path === "/product") {
        this.navBarList = [
          {url: '/',name: this.$t("common.base.nav_index")},
          {url: '/product?type=0',name: this.$t("common.base.nav_products")}
        ]
        this.categoryLists.forEach(ele=>{
          if (ele.url.split("=")[1] === this.$route.query.type) {
            this.navBarList.push({
              name: ele.name,
              url: ele.url
            })
          }
        })
      }
      if (this.$route.path === "/news") {
        // let obj = cur[this.picIndex]
        this.navBarList = [
          {url: '/',name: this.$t("common.base.nav_index")},
          {url: '/news?type=0',name: this.$t("common.base.nav_news")},
        ]
        let pageType = this.$route.query.type
        if (pageType != 0) {
          this.navBarList.push({
            name: arr[newIndex].children[pageType - 1].name,
            url: arr[newIndex].children[pageType - 1].name
          })
        }
      }
      // console.log(cur, this.name, query)
      // console.log(this.cur_index)
      // console.log(this.picIndex)
      // console.log("------cur------", cur);
      return cur;
    },
  },
  mounted() {
    this.getCategoryList()
    // console.log(this.curList,998,this.cur_index)
    if (JSON.stringify(this.curList) === '{}') return
    this.navBarList.push({
      name: this.curList.name,
      url: this.curList.url
    })
    if (this.cur_index !== "" && this.curList.url !== this.curList.children[this.cur_index].url) {
      this.navBarList.push({
        name: this.curList.children[this.cur_index].name,
        url: this.curList.children[this.cur_index].url
      })
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(newVal,oldVal) {
        if (newVal !== oldVal && this.$route.path === "/product") {
          this.getCategoryList()
        }
      }
    }
  },
  created() { },
  methods: {
    toNavBarPage(obj) {
      console.log(obj)
      // if (obj.url == '/product?type=0' || obj.url == '/news?type=0') {

      // }
      this.$router.push({
        path: obj.url
      })
    },
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          if (this.$route.path === "/product") {
            this.categoryLists.forEach(ele=>{
              if (ele.url.split("=")[1] === this.$route.query.type) {
                this.navBarList.push({
                  name: ele.name,
                  url: ele.url
                })
              }
            })
          }
          console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
    gopage(item) {
      if (this.curList.url === "/news?type=0") {
        this.navBarList.pop()
      }
      if (item.url !== this.curList.url) {
        this.navBarList.push({
          name: item.name,
          url: item.url
        })
      }
      this.$router.push({
        path: item.url,
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>


.title {
  font-size: 0.43rem;
  font-weight: 800;
  color: #ffffff;
  display: block;
}

.subTitle {
  font-size: 0.22rem;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
  display: block;
}

.phonePicBox {
  div {
    left: .2rem !important;
    transform: translateY(-50%);
  }
  
  .title {
    font-size: .7rem;
  }

  .subTitle {
    font-size: .28rem;
    margin-top: .16rem;
  }

  img {
    height: 3.4rem !important;
    object-fit: cover;
  }
}
 
.top-picbox {
  width: 100%;
  // height: 4.3rem;
  overflow: hidden;
  position: relative;

  div {
    position: absolute;
    left: 2.4rem;
    top: 50%;
    // transform: translateY(-50%);
    color: #ffffff;
    font-size: 0.3rem;
    font-weight: bold;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  
}

.top-positionbg {
  // border-bottom: 1px solid #ECECEC;
}
.noBorder {
  border: 0;
  .top-position {
    margin: 0 auto;
    padding: .3rem .3rem .10rem;
  }
}

.top-position {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
  margin: 0.35rem auto 0.2rem;
  font-size: .18rem;
  color: #A7A7A7;

  // .top-position-l {
  //   display: flex;
  //   align-items: center;

  //   img {
  //     width: 0.14rem;
  //     height: 0.14rem;
  //     display: block;
  //     margin-right: 0.06rem;
  //   }

  //   .top-position-l-msg {
  //     display: flex;
  //     align-items: center;

  //     div {
  //       cursor: pointer;
  //       padding: 0.04rem 0.06rem;
  //     }
  //   }
  // }
  .leftNavBar {
    display: flex;
    
    .navbarItem {
      span {
        margin: .06rem;
      }
      
    }
  } 
  .top-position-r {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    
    

    .top-position-r-item {
      cursor: pointer;
      // margin-right: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;

      .active {
        // background: #19cc41 !important;
        color: #1FCE76 !important;
        border-color: #1FCE76 !important;
        font-weight: bold !important;
      }

      .txt {
        // width: 186px;
        // height: 46px;
        // width: 1.2rem;
        padding: 0 .2rem;
        height: .4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        font-weight: 400;
        border: 1px solid #ececec;
        // margin-top: 50px;
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 溢出部分隐藏 */
        text-overflow: ellipsis;
        /* 显示省略号 */

      }

      span {
        width: 100%;
        background: #ffffff;
        height: 0.02rem;
      }

      img {
        width: 25px;
        height: 12px;
        // position: absolute;
        // bottom: -26px;
        display: block;
        // left: 50%;
        // transform: translateX(-50%);
        margin-top: 0.15rem;
      }

      .spanact {
        background: #51b059;
      }
    }

  }
  .phoneClass {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    font-size: .26rem;
    color: #A7A7A7;
    // padding-left: .4rem;
    .top-position-r-item {
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .rightLine {
        width: .02rem;
        height: .2rem;
        margin: 0 .3rem;
        background: #EEEEEE;
      }
      .active {
        color: #262830;
        font-weight: 800;
        font-size: .34rem;
      }
    }
  }
  .phoneClass::-webkit-scrollbar {
    display: none;
  }
}
</style>
