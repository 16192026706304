<template>
  <div class="fa-container">
    <!-- <el-carousel arrow="always" :interval="2000" style="overflow: hidden; height: 8rem; z-index: 10">
      <el-carousel-item v-for="(item, ind) in swiperList" :key="ind">
        <div class="swier-tem">
          <img :src="item.img" alt="" />
          <div class="rightModelTextBox" :class="{ rightModelTextBoxHover: switchIndex == ind }">
            <div class="modeLogo">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/logoBai.png" alt="" />
            </div>
            <div class="modeTitle">{{ $t("pages.main.imgList")[ind].title }}</div>
            <div class="modeCom">{{ $t("pages.main.imgList")[ind].content }}</div>
            <div class="modeBtn1" @click="goPages(item)">{{ $t("pages.main.btnText") }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel> -->
    <transition name="fade">
    <div class="indexModel"
    v-show="showModel"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    >
      <div class="modelTitle">
        <p>
          致力成为新能源
        </p>
        <p>
          行业领先锂电公司
        </p>
      </div>
      <div class="enTitle">
        Strive to be New energy industry leading lithium battery company
      </div>
      <div class="toMore" @click="toIntroduce">
        <span>{{ $t("pages.main.banner1_t3") }}</span>
        <img src="@/assets/icon/icon_go.png" alt="">
      </div>
      <div class="showMore">
        上滑探索更多
        <img class="bottomIcon" src="@/assets/icon/icon_xia@2x.png" alt="">
      </div>
    </div>
    </transition>
    <div class="index-bg">
      <div class="index-tt">
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.proplan") }}</span>
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <!-- <span>{{ $t("pages.main.proplan_desc") }}</span> -->
      </div>
      <div class="shopCardArea">
        <phone-shop-card-vue
        v-for="(item, ind) in fanganList"
        :key="ind"
        :cardWidth="3.4"
        :dataObj="item"
        ></phone-shop-card-vue>
        <!-- <div class="index-fa-item wow fadeInUp" v-for="(item, ind) in fanganList" :key="ind">
          <img class="index-fa-item-img" :src="item.img" alt="" />
          <div class="index-fa-item-div">
            <h2>{{ item.name }}</h2>
            <p>{{ item.desc }}</p>
            <div @click="goPages(item)">
              <span>{{ $t("pages.main.proplan_more") }}</span>
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-common/next.png" alt="" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="introduceArea">
      <!-- <div class="index-tt wow fadeInUp">
        <h1>{{ $t("pages.main.shengli") }}</h1>
        <span>{{ $t("pages.main.shengli_desc") }}</span>
      </div> -->
      <div class="wow fadeInUp">
        <div class="introTitle">
            <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
            <span>{{ $t("pages.main.shengli_t1") }}</span><span> · </span>
            <span >{{ $t("pages.main.shengli_t2") }}</span>
            <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        </div>
        <div class="introContent">
          <p>{{ $t("pages.main.shengli_p1") }}</p>
          <p>{{ $t("pages.main.shengli_p2") }}</p>
        </div>
        <div class="numBox">
          <div class="item">
            <p>
              <span>20</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice1") }}</p>
          </div>
          <div class="item">
            <p>
              <span>88000</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice2") }}</p>
          </div>
          <div class="item">
            <p>
              <span>100</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice3") }}</p>
          </div>
          <div class="item">
            <p>
              <span>3800</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice4") }}</p>
          </div>
        </div>
        <!-- <div class="index-cont-r">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/index_company.jpg" alt="" />
        </div> -->
      </div>
    </div>
    <div class="index-bg">
      <div class="index-tt wow fadeInUp">
        <!-- <h1>{{ $t("pages.main.friend") }}</h1> -->
        <!-- <span>{{ $t("pages.main.friend_desc") }}</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.friend") }}</span>
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <div class="modelArea">
        <div class="centerBox">
          200+
        </div>
        <div class="companyBox"
        v-for="(item,index) in componyList"
        :key="index"
        :style="{'top': item.top + 'rem',
        'left': item.left + 'rem'
        }"
        >
        <img :src="item.img" alt="">
        </div>
      </div>
      <!-- <div class="index-friend wow fadeInUp">
        <div class="index-friend-cont">
          <div class="index-friend-item" v-for="(item, ind) in huoban" :key="ind">
            <img :src="item" alt="" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="index-bg bgfa">
      <div class="index-tt wow fadeInUp newsClass">
        <!-- <h1>{{ $t("pages.main.news") }}</h1>
        <span>{{ $t("pages.main.news_desc") }}</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.news") }}</span>
        
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <!-- <div class="index-new-top wow fadeInUp">
        <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
          <el-radio-button :label="1">{{ $t("common.base.nav_news_companynews") }}</el-radio-button>
          <el-radio-button :label="2">{{ $t("common.base.nav_news_technical") }}</el-radio-button>
          <el-radio-button :label="3">{{ $t("common.base.nav_news_problem") }}</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="index-new-box wow fadeInUp">
        <!-- <div class="index-new-box-l wow fadeInUp" v-for="(item, index) in newsComList[0]" :key="index">
          <div class="index-new-box-l-top wow fadeInUp" @click="goDetail(item.id, item.url)">
            <img :src="item.img" alt="" />
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="index-new-box-l-btm"></div>
        </div> -->
        <div class="titleNews" @click="goDetail(newsComList[0].id, newsComList[0].url)">
          <img :src="newsComList[0].img" alt="">
          <div class="newContent">
            <div class="titleNewsName">{{newsComList[0].name}}</div>
            <div class="titleNewsDate">{{newsComList[0].time}}</div>
            <div class="titleNewsDesc">{{newsComList[0].desc}}</div>
            <img class="toDetail" src="@/assets/icon/icon_jin.png" alt="">
          </div>
        </div>
        <div class="index-new-box-r">
          <div class="index-new-box-r-item wow fadeInUp" v-for="(item, index) in newsComList" :key="index" @click="goDetail(item.id, item.url)">
            <!-- <div>{{ item.name }}</div>
            <p>{{ item.desc }}</p>
            <span>{{ item.time }}</span> -->
            <div class="timeBox">
              <div class="dayTime">{{item.time.slice(-2)}}</div>
              <div class="monthTime">{{item.time.substring(0,item.time.lastIndexOf("-"))}}</div>
            </div>
            <div class="contentNewsBox">
              <div class="contentTitle">{{ item.name }}</div>
              <div class="contentDetail">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toMoreBtn" @click="toMoreNews">{{ $t("pages.main.proplan_more") }}</div>
  </div>
</template>

<script>
import phoneShopCardVue from '@/components/phoneShopCard.vue';
export default {
  name: "PcPage",
  components: {
    phoneShopCardVue
  },
  data() {
    return {
      switchIndex: 0,
      isCollapse: 1,
      swiperList: [
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/swiper1.jpg",
          url: "/product?type=1",
        },
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/swiper2.jpg",
          url: "/product?type=3",
        },
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/swiper3.jpg",
          url: "/product?type=3",
        },
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/swiper4.jpg",
          url: "/product?type=2",
        },
      ],
      huoban: ["https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban11.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban12.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban13.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban14.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban15.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban16.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban21.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban22.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban23.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban24.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban25.jpg", "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/huoban26.jpg"],
      componyList: [
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_byd%402x.png',
          top: 6.16,
          left: .7
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_changhong%402x.png',
          top: 5.76,
          left: 5.2
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_geli%402x.png',
          top: 4.32,
          left: 0
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_huawei%402x.png',
          top: 8.54,
          left: 0
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_oppo%402x.png',
          top: .66,
          left: 3.06,
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_pingan%402x.png',
          top: 1.34,
          left: 0
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_tailing%402x.png',
          top: 8.04,
          left: 3.64
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_xiaomi%402x.png',
          top: 3.3,
          left: 5.2
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_zhongxing%402x.png',
          top: 0.2,
          left: 5.2
        },
      ],
      startY: 0,
      showModel: true
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    // 添加滚动事件监听器
    window.addEventListener('scroll', this.debounce(this.handleScroll,500));
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    fanganList() {
      return [
        {
          name: this.$t("pages.main.proplan_t1"),
          desc: this.$t("pages.main.proplan_t1_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product1.png",
          type: 1,
          url: "/product?type=1",
          id: 9
        },
        {
          name: this.$t("pages.main.proplan_t2"),
          desc: this.$t("pages.main.proplan_t2_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product2.png",
          type: 2,
          url: "/product?type=2",
          id: 10
        },
        {
          name: this.$t("pages.main.proplan_t3"),
          desc: this.$t("pages.main.proplan_t3_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product3.png",
          type: 3,
          url: "/product?type=3",
          id: 11
        },
        {
          name: this.$t("pages.main.proplan_t4"),
          desc: this.$t("pages.main.proplan_t4_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product4.png",
          type: 4,
          url: "/product?type=4",
          id: 12
        },
        {
          name: this.$t("pages.main.proplan_t5"),
          desc: this.$t("pages.main.proplan_t5_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product5.png",
          type: 5,
          url: "/product?type=7",
          id: 19
        },
        {
          name: this.$t("pages.main.proplan_t6"),
          desc: this.$t("pages.main.proplan_t6_desc"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product6.png",
          type: 6,
          url: "/product?type=6",
          id: 18
        },
      ];
    },
    newsComList() {
      let obj = this.$t("pages.news.news" + this.isCollapse);
      return obj.list[0];
    },
  },

  methods: {
    debounce(func, wait) {
      let timeout;
      return function debounced() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    handleScroll() {
      // 检查是否滚动到了顶部
      if (window.scrollY <= 0) {
        this.showModel = true
      }
    },
    toIntroduce() {
      this.$router.push({
        path: '/introduction',
      });
    },
    handleTouchStart(e) {
      // 记录触摸开始的Y坐标
      this.startY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      e.preventDefault()
      // 当前触摸的Y坐标
      const currentY = e.touches[0].clientY;
      if (currentY < this.startY) {
        console.log('向下滑动');
        this.showModel = false
        document.body.style.overflow = 'auto';
        setTimeout(() => {
          window.scrollTo({
            top: 1,
          })
        }, 500);
      }
    },
    switchChange(e) {
      console.log(e);
      this.switchIndex = e;
    },
    toMoreNews() {
      this.$router.push({
        path: '/news',
        query: {
          type: 1
        }
      });
    },
    goPages(item) {
      this.$router.push({
        path: item.url,
        query: {
          id: item.id
        }
      });
    },
    // 去详情
    goDetail(id, url) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          name: url,
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indexModel {
  height: 100vh;
  background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/phoneIndexBg.png') no-repeat;
  background-size: 100% 100%;
  padding: 1.72rem 0 0 .4rem;
  transition: height 2s ease;
  .modelTitle {
    font-size: 0.70rem;
    color: #fff;
    font-weight: 800;
    line-height: .88rem;
  }
  .enTitle {
     font-size: 0.28rem;
     color: #fff;
     font-weight: 300;
     line-height: .42rem;
     margin-top: .4rem;
  }
  .toMore {
    margin-top: 2.04rem;
    color: #1FCE76;
    font-size: .32rem;
    img {
      width: 2.4rem;
      margin-left: .56rem;
    }
  }
  .showMore {
    position: absolute;
    bottom: 0.35rem;
    left: 50%;
    transform: translate(-50%);
    font-size: .24rem;
    color: #475350;
    display: flex;
    flex-direction: column;
    align-items: center;
                background: -webkit-linear-gradient(135deg,
                    #475350,
                    #3f4f4e 25%,
                    #fff 50%,
                    #d4d7d7 55%,
                    #a7adab 60%,
                    #455557 80%,
                    #475350);
            /* 文字颜色填充设置为透明 */
            -webkit-text-fill-color: transparent;
            /* 背景裁剪，即让文字使用背景色 */
            background-clip: text;
            /* 背景图放大一下，看着柔和一些 */
            background-size: 200% 100%;
            /* 应用动画flowCss 12秒速度 无限循环 线性匀速动画*/
            animation: flowCss 12s infinite linear;
    .bottomIcon {
      width: 0.35rem;
      height: 0.48rem;
      margin-top: .3rem;
    }
  }
  @keyframes flowCss {
            0% {
                /* 移动背景位置 */
                background-position: 0 0;
            }

            100% {
                background-position: -400% 0;
            }
        }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.index-bg {
  width: 100%;
  margin: 0.3rem auto;
  .index-tt {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.8rem 0.3rem 0.3rem;
    font-weight: 800;
    font-size: .44rem;
    color: #1b1b1b;
    h1 {
      color: #87ca8d;
      font-size: 0.3rem;
      letter-spacing: 2px;
      margin-top: 0.08rem;
    }
    :first-child {
          //margin-right: .2rem;
        }

      :last-child {
        //margin-left: .2rem;
      }
    // span {
    //   color: #626362;
    //   font-size: 0.26rem;
    //   line-height: 0.4rem;
    //   margin: 0.08rem;
    //   text-align: center;
    // }
  }
  .index-cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0.3rem;
    .index-cont-l {
      margin-right: 0.4rem;
      flex: 1;
      h3 {
        font-size: 0.3rem;
        color: #87ca8d;
        margin-bottom: 0.3rem;
      }
      p {
        text-indent: 2em;
        font-size: 0.14rem;
        line-height: 0.4rem;
        margin-top: 0.2rem;
      }
    }
    .index-cont-r {
      margin-top: 0.4rem;
      img {
        width: 100%;
        height: 3.98rem;
        display: block;
      }
    }
  }
  .shopCardArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 .24rem;
  }
  .index-fa-item {
    display: flex;
    flex-direction: column;
    padding: 0 0.3rem;
    .index-fa-item-img {
      width: 100%;
      height: 3rem;
      display: block;
    }
    .index-fa-item-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      h2 {
        font-size: 0.4rem;
        color: #000;
        text-decoration-line: underline;
        line-height: 0.6rem;
        padding-left: 0.3rem;
        margin-top: 0.3rem;
      }
      p {
        font-size: 0.3rem;
        color: #626362;
        line-height: 0.5rem;
        margin: 0.3rem 0;
        padding-left: 0.3rem;
      }
      div {
        margin-left: 0.3rem;
        display: flex;
        align-items: center;
        border: 1px solid #626362;
        border-radius: 4px;
        width: 2.2rem;
        height: 0.6rem;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 0.3rem;
        span {
          font-size: 0.3rem;
          color: #626362;
        }
        img {
          width: 0.24rem;
          height: 0.24rem;
          display: block;
          margin-left: 0.1rem;
        }
      }
    }
  }
  .index-friend {
    .index-friend-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0.3rem;
      .index-friend-item {
        width: 2.26rem;
        height: 1.13rem;
        // border-right: 1px solid #D0D1D0;
        border: 1px solid #d0d1d0;
        box-sizing: border-box;
        overflow: hidden;
        img {
          width: 2.26rem;
          height: 1.13rem;
          display: block;
        }
      }
    }
  }
  .index-new-top {
    display: flex;
    justify-content: center;
    ::v-deep .el-radio-button__inner {
      padding: 0.14rem 0.06rem;
    }
  }
  .newsClass {
    background: #fafafa;
    padding: 0.8rem 0.3rem 0.3rem;
    margin: 0 !important;
  }
  .modelArea {
    position: relative;
    height: 12rem;
    color: #fff;
    font-size: .62rem;
    font-weight: 800;
    .centerBox {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 1.92rem;
      height: 1.92rem;
      text-align: center;
      line-height: 1.92rem;
      border-radius: 50%;
      background: #1FCE76;
    }
    .companyBox {
      position: absolute;
      width: 2.44rem;
      height: 2.44rem;
      border-radius: 50%;
      animation: bellShow 2s infinite;
      img {
        width: 100%;
      }
    }
    @keyframes bellShow {
            0% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(0, 0, 0, 10%);
            }

            60% {
                transform: scale(1);
                // box-shadow: 0 0 0 5px rgba(31, 206, 118, 0%);
            }

            100% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(31, 206, 118, 0%);
            }
        }
  }
}

.introduceArea {
  width: 100%;
  margin: 0.3rem auto;
  padding: 1rem .56rem .88rem;
  background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_lvsedogli%402x.png') no-repeat center;
  .introTitle {
      font-weight: 600;
      font-size: 0.44rem;
      color: #fff;
      text-align: center;
      :first-child {
          margin-right: .2rem;
        }

      :last-child {
        margin-left: .2rem;
      }
  }
  .introContent {
    font-size: .24rem;
    color: #A8ABA9;
    line-height: .42rem;
    p {
      margin-top: 1rem;
      text-align: center;
    }
  }
  .numBox {
    width: 6rem;
    height: 4.22rem;
    background: #FFFFFF;
    border-radius: 0px 1rem 0px 1rem;
    margin: .88rem auto 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          &:first-child {
            font-weight: bold;
            font-size: 0.46rem;
            color: #00B52D;
            position: relative;

            span {
              &:first-child {
                font-weight: bold;
                font-size: 0.6rem;
                color: #00B52D;
              }

              &:last-child {
                font-weight: bold;
                font-size: 0.36rem;
                color: #00B52D;
                position: absolute;
                top: -0.2rem;
                right: -0.2rem;
                z-index: 2;
              }
            }
          }

          &:last-child {
            font-weight: 600;
            font-size: 0.28rem;
            color: #262830;
            margin-top: 0.11rem;
          }
        }
      }
  }
}

.toMoreBtn {
  width: 4.56rem;
  height: .88rem;
  line-height: .88rem;
  text-align: center;
  color: #1FCE76;
  font-size: .32rem;
  border: 1px solid #1FCE76;
  margin: .4rem auto;
}

::v-deep .el-carousel__container {
  height: 8rem;
}
::v-deep .el-carousel__item {
  height: 8rem;
  img {
    width: 100%;
    height: 2.8rem;
    display: block;
  }
}
.swier-tem {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rightModelTextBox {
  text-align: center;
  background: rgba(0, 27, 77, 0.8);
  height: 100%;
  z-index: 10;
  transition: all 1s;
  // background-color: red;
  color: #fff;
  display: flex;
  flex-direction: column;
  //  align-items: center;
  justify-content: center;
  align-items: center;
  .modeLogo {
    width: 85px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .modeTitle {
    font-size: 25px;
    color: #fff;
    font-weight: bold;
    line-height: 50px;
  }
  .modeCom {
    color: #dedede;
    font-size: 14px;
    // line-height: 56px;
    text-transform: uppercase;
  }
  .modeBtn1 {
    width: 165px;
    height: 53px;
    background: #36bf6a;
    line-height: 53px;
    font-size: 22px;
    display: block;
    color: #fff;
    // letter-spacing: 5px;
    text-align: center;
    border-radius: 30px;
    margin-top: 20px;
    border: 1px solid #fff;
  }
}

.rightModelTextBoxHover {
  left: 50%;
}
.index-new-box {
  // display: flex;
  // align-items: center;
  background: #fafafa;
  padding: 0 .4rem;
  .titleNews {
    background: #fff;
    width: 100%;
    height: 8.34rem;
    img {
      width: 100%;
      height: 4.14rem;
    }
    .newContent {
      padding: .26rem .3rem .42rem;
      font-size: .24rem;
      position: relative;
      .titleNewsName {
        font-size: .36rem;
        color: #3A3C43;
        font-weight: bold;
      }
      .titleNewsDate {
        color: #1FCE76;
        margin: .28rem 0;
      }
      .titleNewsDesc {
        color: #BABABA;
      }
      .toDetail {
        width: 1.2rem;
        height: .36rem;
        position: absolute;
        right: .3rem;
        bottom: -.26rem;
      }
    }
  }

  .index-new-box-l {
    .index-new-box-l-top {
      width: 100%;
      height: 250px;
      position: relative;

      display: block;

      img {
        width: 100%;
        height: 100%;
      }

      .name {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 20px;
        bottom: 0;
        // color: ;
        background-color: rgba(0, 27, 77, 0.8);
        padding: 10px 20px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .index-new-box-r {
    // flex: 1;
    // height: 360px;
    // display: flex;
    // align-items: flex-start;
    // flex-wrap: wrap;
    // font-size: 16px;
    // padding: 20px;
    // justify-content: space-between;

    .index-new-box-r-item {
      padding: .4rem .3rem;
      display: flex;
      justify-content: flex-start;
      background: #fff;
      margin-top: .16rem;

      .timeBox {
        width: 1.36rem;
        height: 1.96rem;
        flex-shrink: 0;
        
        .dayTime {
          background: #e8ffed;
          height: 1.32rem;
          text-align: center;
          line-height: 1.32rem;
          color: #262830;
          font-size: .6rem;
          font-weight: 800;
        }
        .monthTime {
          font-size: .28rem;
          height: .6rem;
          line-height: .6rem;
          text-align: center;
          color: #FFFFFF;
          background: #1FCE76;
        }
      }
      .contentNewsBox {
        overflow: hidden;
        margin-left: .32rem;
        .contentTitle {
          font-weight: bold;
          font-size: .36rem;
          color: #3A3C43;
          width: 100%;
          text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
          margin-bottom: .24rem;
        }
        .contentDetail {
          font-size: .24rem;
          color: #BABABA;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }

    .index-new-box-r-item:hover {
      color: #00205b;
    }
  }
}
</style>
