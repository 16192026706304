// pages模块里面的main部分(页面)数据
const info = {
  ZH: { // 中文
    zs1: 'ROHS环保管理体系认证证书',
    zs2: '高新技术企业证书',
    zs3: 'CE认证证书',
    companyp1: '盛利集团致力于成为绿色能源领航者，以环保动力电池、风能太阳能储能电池、数码设备电池、电动助力车等为一体的大型实业集团。集团旗下子公司20多家，主导项目遍及全球100多个国家和地区，在海外设有子公司和办事处。以研发、生产、销售为一体的新能源科技集团公司。主要产品为锂电池、动力电池；以定制、批发为主；通过诸多国际认证，拥有多项知识产权专利证书；产品远销海外，国内主要和品牌电动车商合作。',
    companyp2: '公司致力于成为新能源锂电池系统领航者，研发人员超过100人，自主研发的动力电池具有高安全性、高功率性、强环境适应性和长循环寿命等优势，备受市场广泛认可目前已经顺利通过 ISO9001-2008质量管理体系认证；相关产品也先后通过了CE、RoHS 、UN38.3、BIS等相关体系认证，产品已通过信息产业化学物理电源产品质量监督检验中心、北京201所等多家权威检验机构的各项检测及认证。',
    companyp3: '公司一贯秉承以“专业、专注，工匠精神”，通过整合行业优势资源,聚集行业各类人才,坚持自主研发，配备先进，高效的生产流水线以及精密的检测设备，不断优化生产流程，提高生产效率，改善产品品质，经过近10年的务实经营；产品以其高容量、高安全性、超长的循环寿命等优点，深受广大客户好评！',
    culture_location:'定位',
    culture_idea:'理念',
    culture_target:'目标',
    culture_mission:'使命',
    culture_location_desc:'新能源行业领先锂电公司',
    culture_idea_desc:'诚信、敬业、务实、创新',
    culture_target_desc:'国际化、品牌化、专业化',
    culture_mission_desc:'成就客户、成就品牌、成就员工',
    culture__desc1:'新能源行业领先锂电公司',
    culture__desc2:'诚信、敬业、务实、创新',
    culture__desc3:'国际化、品牌化、专业化',
    culture__desc4:'成就客户、成就品牌、成就员工',
  },
  EN: { // 英文
    zs1: 'CE Certification',
    zs2: 'High-tech Enterprise Certificate',
    zs3: 'ROHS',
    companyp1: 'Shengli Group is committed to becoming a leader in green energy, a large industrial group integrating environmental protection power batteries, wind and solar energy storage batteries, digital equipment batteries, electric moped, etc. The group has more than 20 subsidiaries, leading projects in more than 100 countries and regions around the world, with overseas subsidiaries and offices. To research and development, production, sales as one of the new energy technology group company. The main products are lithium batteries, power batteries; To customize, wholesale mainly; Through many international certifications, with a number of intellectual property patent certificates; The products are exported overseas, and the main domestic electric vehicle manufacturers cooperate with brands.',
    companyp2: 'The company is committed to becoming the leader of new energy lithium battery systems, with more than 100 R&D personnel. The self-developed power battery has the advantages of high safety, high power, strong environmental adaptability and long cycle life, and has been widely recognized by the market. Successfully passed the ISO9001-2008 quality management system certification; related products have also passed CE, RoHS , UN38.3, BIS and other related system certifications, and the products have passed the Information Industry Chemical and Physical Power Supply Product Quality Supervision and Inspection Center, Beijing 201 Institute, etc. Various tests and certifications by authoritative inspection agencies.',
    companyp3: 'The company has always been adhering to the "professional, dedicated, craftsman spirit", through the integration of industry superior resources, gathering all kinds of talents in the industry, insisting on independent research and development, equipped with advanced and efficient production lines and sophisticated testing equipment, continuously optimizing the production process and improving production efficiency , To improve product quality, after nearly 10 years of pragmatic management; the product is well received by customers for its high capacity, high safety, long cycle life and other advantages!',
    culture_location:'orientation',
    culture_idea:'idea',
    culture_target:'target',
    culture_mission:'mission',
    culture_location_desc:'New energy industry leading lithium battery company',
    culture_idea_desc:'Integrity, dedication, pragmatism, innovation',
    culture_target_desc:'International, brand, professional',
    culture_mission_desc:'Achievement of customers, achievements of brands, achievements of employees',
    culture__desc1:'New energy industry leading lithium battery company',
    culture__desc2:'Integrity, dedication, pragmatism, innovation',
    culture__desc3:'International, brand, professional',
    culture__desc4:'Achievement of customers, achievements of brands, achievements of employees',


  },
}
export default info