<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_culture')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <!-- <div class="company-msg wow fadeInUp">
      <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companywh.jpg" alt="">
    </div> -->
    <div class="contentBox">
      <div class="content">
        <div class="txt wow fadeInLeft">
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture_icon.png" />
          <div class="items">
            <span class="left">{{ $t("pages.company.culture_location") }}</span
            ><span class="right">{{ $t("pages.company.culture_location_desc") }}</span>
          </div>
          <div class="items">
            <span class="left">{{ $t("pages.company.culture_idea") }}</span
            ><span class="right">{{ $t("pages.company.culture_idea_desc") }}</span>
          </div>
          <div class="items">
            <span class="left">{{ $t("pages.company.culture_target") }}</span
            ><span class="right">{{ $t("pages.company.culture_target_desc") }}</span>
          </div>
          <div class="items">
            <span class="left">{{ $t("pages.company.culture_mission") }}</span
            ><span class="right">{{ $t("pages.company.culture_mission_desc") }}</span>
          </div>
        </div>
        <div class="img_box wow fadeInRight">
          <div class="img_left" style="margin-right: 15px">
            <div class="img1">
              <div class="notice">{{ $t("pages.company.culture__desc1") }}</div>
            </div>
            <div class="img2">
              <div class="notice">{{ $t("pages.company.culture__desc2") }}</div>
            </div>
            <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture1.png" style="margin-top: 131px;margin-bottom: 15px;"/>
            <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture2.png"/> -->
          </div>
          <div class="img_right">
            <div class="img3">
              <div class="notice">{{ $t("pages.company.culture__desc3") }}</div>
            </div>
            <div class="img4">
              <div class="notice">{{ $t("pages.company.culture__desc4") }}</div>
            </div>

            <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture3.png" style="margin-top: 50px; margin-bottom: 15px" />
            <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture4.png" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/culture",
    };
  },
  methods: {
    goClass() {},
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
  .img_box {
    display: flex;
    .img_left {
      .img1 {
        width: 423px;
        height: 262px;
        background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture1.png") no-repeat center/100%;
        margin-top: 131px;
        padding-top: 223px;
      }
      .img2 {
        width: 423px;
        height: 262px;
        background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture2.png") no-repeat center/100%;
        margin-top: 15px;
        padding-top: 223px;
      }

      img {
        display: block;
        width: 423px;
        height: 262px;
      }
    }
    .img_right {
      .img3 {
        width: 423px;
        height: 262px;
        background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture3.png") no-repeat center/100%;
        margin-top: 50px;
        padding-top: 223px;
      }
      .img4 {
        width: 423px;
        height: 262px;
        background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/culture4.png") no-repeat center/100%;
        margin-top: 15px;
        padding-top: 223px;
      }
    }
  }
  .notice {
    width: 422px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    background: transparent;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 溢出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  .img1:hover {
    .notice {
      background: #36bf6a;
    }
  }
  .img2:hover {
    .notice {
      background: #36bf6a;
    }
  }
  .img3:hover {
    .notice {
      background: #36bf6a;
    }
  }
  .img4:hover {
    .notice {
      background: #36bf6a;
    }
  }


  .txt {
    margin-top: 70px;
    img {
      display: block;
      width: 449px;
      height: 78px;
      margin-bottom: 37px;
    }
    .items {
      position: relative;
      display: flex;
      margin-bottom: 16px;
      .left {
        font-size: 18px;
        font-weight: 600;
        color: #262830;
        // margin-top: 5px;
        white-space: nowrap;
      }
      .right {
        font-size: 16px;
        font-weight: 400;
        color: #717171;
        // position: absolute;
        // top: -5px;
        // left: 50px;
        width: 400px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 溢出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        margin-left: 15px;
      }
    }
  }
}
.company-msg {
  width: 15rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 9.71rem;
    height: 5.21rem;
    object-fit: contain;
    margin: 0.5rem;
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .company-msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 3.6rem;
      object-fit: contain;
      margin: 0.5rem;
    }
  }
}
</style>
