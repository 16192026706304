<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_service')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox phoneClass">
      <div class="service_box">
        <div class="sales wow fadeInUp">
          <div class="left">
            <div class="top" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}" >{{ $t("pages.main.service_title1") }}</div>
            <div class="top" style="color: #262830" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}" >{{ $t("pages.main.service_title4") }}</div>
          </div>
          <div class="right">
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt " :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_pre1") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_pre2") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_pre3") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_pre4") }}</span>
            </div>

          </div>
        </div>
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_aeeow.png" />
        <div class="sales wow fadeInUp" style="animation-delay: .5s">
          <div class="left">
            <div class="top" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}">{{ $t("pages.main.service_title2") }}</div>
            <div class="top" style="color: #262830" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}">{{ $t("pages.main.service_title4") }}</div>
          </div>
          <div class="right">
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_in1") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_in2") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_in3") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_in4") }}</span>
            </div>

          </div>
        </div>
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_aeeow.png" />
        <div class="sales wow fadeInUp" style="animation-delay: 1s">
          <div class="left">
            <div class="top" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}">{{ $t("pages.main.service_title3") }}</div>
            <div class="top" style="color: #262830" :style="{fontSize:$i18n.locale == 'zh'?'':'0.28rem'}">{{ $t("pages.main.service_title4") }}</div>
          </div>
          <div class="right">
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_after1") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_after2") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_after3") }}</span>
            </div>
            <div class="items">
              <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/service_arrow_icon.png" />
              <span class="txt" :class="{ellipsis:$i18n.locale == 'en'}">{{ $t("pages.main.service_after4") }}</span>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      page: 1,
      total: 3,
      name: "/service",
    };
  },
  computed: {
    zsList() {
      return [
        {
          name: this.$t("pages.company.zs1"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs1.jpg",
        },
        {
          name: this.$t("pages.company.zs2"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs2.jpg",
        },
        {
          name: this.$t("pages.company.zs3"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs3.jpg",
        },
      ];
    },
  },
  created() {
    console.log(this.$t("pages"));
  },
  methods: {
    goClass() {},
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style scoped lang="scss">
.ellipsis {
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  //max-width: 1.4rem;
  font-size: .14rem !important;
  display: flex;
  flex-wrap: wrap;
  line-height: .24rem !important;

}
.service_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .85rem 0;
  .sales {
    display: flex;
    align-items: center;
    width: 3.77rem;
    height: 2.17rem;
    background: #ffffff;
    border: 0.01rem solid #1fce76;
    padding: 0.43rem 0;
    padding-left: .34rem;
    .left {
      font-weight: bold;
      font-size: 36px;
      color: #2ad17d;
      margin-right: .5rem;
      .top{
        font-size: .36rem;
      }
    }
    .right {
      .items {
        display: flex;
        align-items: center;
        line-height: .4rem;
        img {
          width: 0.1rem;
          height: 0.08rem;
        }
        .txt {
          font-size: 0.2rem;
          color: #838484;
          margin-left: 0.08rem;
        }
      }
    }
  }
  img {
    width: 0.25rem;
    height: 1.02rem;
  }
}
.image {
  display: block;
  width: 100%;
  // height: 590px;
  object-fit: cover;
  margin-top: 57px;
  margin-bottom: 60px;
}
.company-lc {
  margin: 0.4rem 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    font-size: 0.24rem;
    line-height: 0.36rem;
    margin-top: 0.4rem;
  }
  p {
    font-size: 0.16rem;
    text-indent: 2em;
    line-height: 0.3rem;
  }
}

.company-msg {
  width: 15rem;
  margin: 0.4rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .company-msg-item {
    width: 4.6rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 4.6rem;
      height: 3.45rem;
      object-fit: contain;
    }
    div {
      font-size: 0.15rem;
      color: #666;
      line-height: 0.5rem;
    }
  }
}
.pagebox {
  display: flex;
  justify-content: center;
  align-content: center;
}
// 兼容手机
@media screen and (max-width: 756px) {
  .service_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .85rem 0;
  flex-wrap: wrap;
 
  .sales {
    display: flex;
    align-items: center;
    width: 3.77rem;
    height: 2.17rem;
    background: #ffffff;
    border: 0.01rem solid #1fce76;
    padding: 0.43rem 0;
    padding-left: .34rem;
    margin-bottom: .2rem;
    
    width: 100% !important;
    .left {
      font-weight: bold;
      font-size: 0.4rem;
      color: #2ad17d;
      margin-right: .5rem;
      .top{
        font-size: .4rem;
      }

    }
    .right {
      .items {
        display: flex;
        align-items: center;
        line-height: .4rem;
        img {
          width: 0.1rem;
          height: 0.08rem;
          display: block !important;
        }
        .txt {
          font-size: 0.24rem;
          color: #838484;
          margin-left: 0.08rem;
        }
      }
    }
  }
  img {
    width: 0.25rem;
    height: 1.02rem;
    display: none;
  }
}

  .phoneClass {
    overflow: auto;
    .image {
      width: auto;
    }
  }
  .company-msg {
    width: 100%;
    margin: 0.4rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .company-msg-item {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      img {
        width: 4.6rem;
        height: 3.45rem;
        object-fit: contain;
      }
      div {
        font-size: 0.15rem;
        color: #666;
        line-height: 0.5rem;
      }
    }
  }
  .pagebox {
    display: none;
  }
}
</style>
