<template>
  <div>
    <top-pic :name="name" :text="'新闻'" @clickOne="sonClick"></top-pic>
    <div v-if="(curnewsLists.length && type == '1') || (curnewsLists.length && type == '2')" class="news-msg contentBox">
      <template v-if="$store.state.deviceType === '1'">
        <div class="news-item wow fadeInUp" v-for="item in curnewsLists" :key="item.id" @click="goDetail(item.id)">
          <div class="news-item-l">
            <img :src="item.image" alt="" />
          </div>
          <div class="news-item-r">
            <div class="news-item-r-time fw600">
              {{ item.createTime.substring(8, 11) }}/
              <div class="years">
                <p>{{ item.createTime.substring(5, 7) }}月</p>
                <p>{{ item.createTime.substring(0, 4) }}</p>
              </div>
            </div>
            <div class="news-item-r-tt">{{ item.title }}</div>
            <div class="news-item-r-dec" v-html="item.viceTitle"></div>
            <div class="toDetail">{{ $t("pages.news.detail") }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="phoneNews wow fadeInUp" v-for="item in curnewsLists" :key="item.id" @click="goDetail(item.id)">
          <img :src="item.image" alt="">
          <div class="newsTitle">{{ item.title }}</div>
          <div class="createTime">{{item.createTime.substring(0, 11)}}</div>
          <div class="newsContent" v-html="item.viceTitle"></div>
        </div>
      </template>
    </div>
    <div v-if="curnewsLists.length && type == '3'" class="contentBox pt23">
      <div class="prombles">
        <div class="items  wow fadeInUp" v-for="(item, i) in curnewsLists" :key="i" @click="goDetail(item.id)">
          <div class="question"><span class="icon">Q</span><div class="titleBox">{{ item.title }}</div></div>
          <div class="answer">
            <span class="icon" style="background: #777A7B;">A</span>
            <div style="width: 384px;" v-html="item.viceTitle"></div>
          </div>
        </div>
      </div>
    </div>
    <p class="curnewsListBox" v-if="!curnewsLists.length">{{ $t("pages.news.nomore") }}</p>
    <div class="pagebox wow fadeInUp" v-if="$store.state.deviceType === '1' && total">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.page" :page-sizes="[5, 10]" :page-size="page.per_page" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
    </div>
    <!-- <div class="pagebox" v-if="$store.state.deviceType === '2' && total">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.page" :page-size="[5, 10]" layout="total, prev, pager, next" :total="total"> </el-pagination>
    </div> -->
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
import { articleTrainingList } from "@/utils/api";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/news?type=0",
      curRoute: "",
      page: 1,
      total: 0,
      page: {
        page: 1,
        per_page: 10,
      },
      curnewsLists: [],
      type: "1",
    };
  },
  computed: {
    // 页面导航
    pronavList() {
      return [
        {
          name: this.$t("common.base.nav_news_companynews"),
          url: "/news?type=1",
        },
        {
          name: this.$t("common.base.nav_news_technical"),
          url: "/news?type=2",
        },
        {
          name: this.$t("common.base.nav_news_problem"),
          url: "/news?type=3",
        },
      ];
    },
    // 显示的新闻
    // curnewsList() {
    //   let str = this.curRoute
    //   let msg = null
    //   let list = []
    //   if (str == '/news?type=0') {
    //     msg = this.$t('pages.news.newsAll')
    //   } else if (str == '/news?type=1') {
    //     msg = this.$t('pages.news.newsOne')
    //   } else if (str == '/news?type=2') {
    //     msg = this.$t('pages.news.newsTwo')
    //   } else if (str == '/news?type=3') {
    //     msg = this.$t('pages.news.newsThree')
    //   }
    //   this.total = msg.total
    //   list = msg.list[this.page - 1]
    //   return list
    // }
  },
  watch: {
    $route: function () {
      if (this.$route.fullPath.indexOf("news") > -1) {
        console.log("------------rrrr--------", this.$route.query);
        this.type = this.$route.query.type;
        this.setMsg(this.$route.fullPath);
        this.page.page = 1;
        this.getDate();
      }
    },
  },
  created() {
    this.setMsg(this.$route.fullPath)
    this.type =  this.$route.query.type == 0 ? '3' : this.$route.query.type
    this.getDate();
  },
  methods: {
    getDate() {
      articleTrainingList({ page: this.page, language: this.$i18n.locale, state: 0, type: this.$route.query.type }).then((res) => {
        if (res.code == "000000") {
          this.curnewsLists = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 设置数据
    setMsg(url) {
      this.name = url;
      this.curRoute = url;
    },
    // 页面跳转
    goPages(url) {
      this.page.page = 1;
      this.$router.push({
        path: url,
      });
    },
    // 去详情
    goDetail(id) {
      console.log(this.name)
      this.$router.push({
        path: "/newsDetail",
        query: {
          name: this.name,
          id,
        },
      });
    },
    handleSizeChange(page) {
      //console.log('1', page)
      this.page.per_page = page;
      this.getDate();
    },
    handleCurrentChange(page) {
      //console.log('2', page,)
      this.page.page = page;
      this.getDate();
    },
    sonClick() {
      this.page.page = 1;
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #19cc41;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
  margin-right: 13px;
  flex-shrink: 0;
}

.pt23 {
  padding-top: 23px
}

.prombles {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;

  .items {
    border-bottom: 1px solid #ececec;
    padding: 50px 0;
    width: 33.33%;
    .question {
      font-size: 16px;
      font-weight: 600;
      color: #262830;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      height: .6rem;
      .titleBox {
        display: flex;
        width: 384px;
        flex-wrap: wrap;
      }
    }
    .answer {
      font-size: 14px;
      font-weight: 400;
      color: #717171;
      line-height: 25px;
      display: flex;
    }
  }

}
.prombles::after{
    content: "";
    display: block;
    flex: auto;
  }


.news-msg {
  padding-top: 56px;
  .news-item {
    display: flex;
    transition: all 0.25s;
    margin-bottom: 40px;
    .news-item-l {
      width: 329px;
      height: 223px;
      overflow: hidden;
      margin-right: 28px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.25s;
      }
    }
    .news-item-r {
      flex: 1;
      font-size: 0.14rem;
      .toDetail {
        width: 125px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #19cc41;
        font-size: 14px;
        font-weight: 400;
        color: #19cc41;
      }
      .news-item-r-tt {
        font-size: 18px;
        font-weight: 600;
        color: #262830;
        line-height: 22px;
        margin: 21px 0 18px 0;
      }
      .news-item-r-dec {
        font-size: 14px;
        font-weight: 400;
        color: #717171;
        line-height: 22px;
        margin-bottom: 18px;
      }
      .news-item-r-time {
        display: flex;
        color: #272931;
        font-size: 40px;
        align-items: center;
        .years {
          font-size: 14px;
          font-weight: bold;
          color: #717171;
          line-height: 16px;
          margin-left: 8px;
        }
      }
    }
  }
  .news-item:hover {
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    img {
      //transform: scale(1.2);
    }
  }
}

.curnewsListBox {
  text-align: center; 
  font-size: 0.3rem; 
  margin-bottom: .3rem;
}

.pagebox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phoneNews {
  font-size: .24rem;
  padding-bottom: .6rem;
  margin-bottom: .6rem;
  border-bottom: 1px solid #EEEEEE;
  img {
    width: 100%;
    height: 3.44rem;
    object-fit: cover;
  }
  .newsTitle {
    margin: .18rem 0 .1rem;
    color: #262830;
    font-size: .34rem;
    font-weight: 800;
  }
  .createTime {
    color: #A7A7A7;
    margin-bottom: .28rem;
  }
  .newsContent {
    color: #717171;
    
    word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .news-msg {
    width: 100%;
    margin: 0.4rem auto;
    padding: 0 0.3rem;
    .news-item {
      display: flex;
      flex-direction: column;
      border: 1px solid #dedede;
      padding: 0.3rem;
      margin-bottom: 0.3rem;
      border-radius: 5px;
      .news-item-l {
        width: 100%;
        height: 3rem;
        overflow: hidden;
        margin-right: 0.3rem;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }
      .news-item-r {
        margin-top: 0.2rem;
        font-size: 0.14rem;
        .news-item-r-tt {
          font-size: 0.28rem;
          color: #333;
          line-height: 0.5rem;
          border-bottom: 1px solid #dedede;
          font-weight: bold;
        }
        .news-item-r-dec {
          color: #333;
          margin: 0.1rem 0;
          line-height: 0.3rem;
        }
        .news-item-r-time {
          color: #999;
        }
      }
    }
  }
}
</style>
