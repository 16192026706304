<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_course')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox phoneClass " v-if="deviceTypes == '1'" style="position: relative;">
      <img class="content-img" @load="handleLoad" :style="{ opacity: opacity }" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/course_bg.png" alt="" />
        <div class="course_items wow fadeInUp" v-for="(item, i) in listPc" :key="i" :style="{ top: item.top, width: item.width, left: item.left +'%',paddingRight:$i18n.locale == 'zh'?item.padding:0}" >
          <div class="course_line" v-if="i == 0 || i==1 || i==2 || i==3"></div>
          <div class="course_line1" v-else></div>
          <div>
            <div class="course_title" :class="{'bottom':(i==4 && $i18n.locale == 'zh' ) || (i==5 && $i18n.locale == 'zh' ) ||(i==6 && $i18n.locale == 'zh' ) }">{{ item.time }}</div>
            <div class="course_txt">{{ item.txt }}</div>
          </div>
        </div>
    </div>
    <div class="contentBox" v-else>
      <div class="items" v-for="(item, i) in list" :key="i" :class="{ borderLeft: i == list.length - 1 }">
        <div class="icon_box">
          <div class="dot"></div>
          <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/develop_arrow.png" />
          <div class="circle"></div>
          <div class="year">{{ item.time }}</div>
        </div>
        <div class="txt_box">
          <div class="txt_top">{{ item.txt1 }}</div>
          <div class="txt_bottom">{{ item.txt2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/course",
      opacity: 0,
      name: "/course",
      deviceTypes: "1", // 1电脑 2手机
      
    };
  },
  computed:{
    list(){
      return [
        {
          time: "2007",
          txt1: this.$t("pages.main.course_txt1"),
          txt2: this.$t("pages.main.course_txt2"),
        },
        {
          time: "2012",
          txt1: this.$t("pages.main.course_txt3"),
          txt2: this.$t("pages.main.course_txt4"),
        },
        {
          time: "2014",
          txt1: this.$t("pages.main.course_txt5"),
          txt2: this.$t("pages.main.course_txt6"),
        },
        {
          time: "2016",
          txt1: this.$t("pages.main.course_txt7"),
          txt2: this.$t("pages.main.course_txt8"),
        },
        {
          time: "2017",
          txt1: this.$t("pages.main.course_txt9"),
          txt2: this.$t("pages.main.course_txt10"),
        },
        {
          time: "2019",
          txt1: this.$t("pages.main.course_txt11"),
          txt2: this.$t("pages.main.course_txt12"),
        },
        {
          time: "2020",
          txt1: this.$t("pages.main.course_txt13"),
          txt2: this.$t("pages.main.course_txt14"),
        },
      ]
    },
    listPc(){
      return [
        {
          time: "2007-9",
          txt: this.$t("pages.main.course_pc1"),
          width: "25%",
          top: ".65rem",
          left: 0.1,
          padding:'.67rem'
        },
        {
          time: "2014",
          txt: this.$t("pages.main.course_pc2"),
          width: "25%",
          top: ".45rem",
          left: 25,
          padding:'.67rem'

        },
        {
          time: "2016-10",
          txt: this.$t("pages.main.course_pc3"),
          width: "25%",
          top: "0",
          left: 50,
          padding:'.67rem'

        },
        {
          time: "2019-7",
          txt: this.$t("pages.main.course_pc4"),
          width: "25%",
          top: "-0.5rem",
          left: 76,
          padding:'0'

        },
        {
          time: "2012-3",
          txt: this.$t("pages.main.course_pc5"),
          width: "24%",
          top: "3rem",
          left: 12,
          padding:'.67rem'

        },
        {
          time: "2017-4",
          txt: this.$t("pages.main.course_pc6"),
          width: "25%",
          top: "2.8rem",
          left: 54,
          padding:'.67rem'

        },
        {
          time: "2020-9",
          txt: this.$t("pages.main.course_pc7"),
          width: "19.6%",
          top: "2.4rem",
          left: 81,
          padding:'0'

        },

      ]
    }

  },
  methods: {
    handleLoad() {
      this.opacity = 1;
    },
    goClass() {},
  },

  mounted() {
    this.deviceTypes = this.$store.state.deviceType;
    console.log("this.phone", this.deviceTypes);
  },
};
</script>

<style scoped lang="scss">
.bottom{
  margin-top: .6rem;
}
  .course_items {
    height: 1.56rem;
    display: flex;
    position: absolute;
    padding-right: .67rem;
    z-index: 9;
    top: .83rem;
    .course_title {
      font-weight: bold;
      font-size: 0.2rem;
      color: #1b1b1b;
      margin-bottom: .2rem;
    }
    .course_txt {
      font-weight: 400;
      font-size: 0.18rem;
      color: #717171;
      line-height: 0.3rem;
    }
    .course_line,.course_line1 {
      width: 0.04rem;
      height: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, #1fce76 100%);
      margin-right: 0.22rem;
      flex-shrink: 0;
    }
    .course_line1{
      background: linear-gradient(0deg, #1FCE76 0%, rgba(255,255,255,0) 100%);
    }
  }
.content-img {
  width: 100%;
  object-fit: cover;
  margin-top: 1.41rem;
  display: block;
  margin-bottom: 2.31rem;
  opacity: 0;
  transition: opacity 3s;
}
.borderLeft {
  border-left: none !important;
}
.items {
  display: flex;
  align-items: flex-start;
  border-left: 0.01rem solid #e3e3e3;
  margin-left: 0.05rem;
  padding-bottom: 0.32rem;

  .txt_box {
    font-weight: bold;
    font-size: .34rem;
    color: #262830;
    .txt_top {
      margin-bottom: 0.05rem;
    }
  }
  .icon_box {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: -0.066rem;
    .dot {
      width: 0.14rem;
      height: 0.14rem;
      background: #1fce76;
      border-radius: 50%;
      margin-right: 0.06rem;
    }
    .circle {
      width: 0.06rem;
      height: 0.06rem;
      border-radius: 50%;
      border: 0.02rem solid #1fce76;
      margin-right: 0.05rem;
    }
    img {
      width: 0.54rem;
      height: 0.2rem;
      margin-right: 0.2rem;
    }
    .year {
      position: absolute;
      top: -0.01rem;
      left: 0.32rem;
      font-weight: bold;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}
.company-msg {
  width: 15rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 449px;
    object-fit: contain;
  }
}
.company-lc {
  margin: 0.4rem 0;
  div {
    font-size: 0.16rem;
    line-height: 0.32rem;
    margin-top: 0.4rem;
  }
  p {
    font-size: 0.16rem;
    text-indent: 2em;
    line-height: 0.3rem;
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .phoneClass {
    overflow-x: auto;
    .content-img {
      width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity 3s;
    }
  }
  .company-msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 3.2rem;
      object-fit: contain;
      margin: 0.5rem;
    }
  }
  .company-lc {
    margin: 0.4rem 0;
    padding: 0 0.3rem;
    div {
      font-size: 0.16rem;
      line-height: 0.32rem;
      margin-top: 0.4rem;
    }
    p {
      font-size: 0.16rem;
      text-indent: 2em;
      line-height: 0.3rem;
    }
  }
}
</style>
