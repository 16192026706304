<template>
  <div class="container">
    <!-- <el-carousel indicator-position="none" v-no-scroll>
      <el-carousel-item v-for="(item, ind) in swiperList" :key="ind"> -->
      <div id="topBox" v-for="(item, ind) in boxList" :key="ind">
        <div class="lightBox">
          <div class="dotBox"
          v-for="(item2,i) in item.list"
          :key="i"
          :style="{
            top: item2.position[1] + 'rem',
            left: item2.position[0] + 'rem',
          }"
          :class="{'reverse': item2.left}"
          @click="goProduct(item2)"
          >
            <div class="dot"></div>
            <div class="textBox"
            :style="{opacity: showTips ? 1 : 0 }"
            >{{item2.text}}</div>
          </div>
        </div>
        <!-- <div class="bannerText" v-show="ind == 0">
          <div class="textClass">{{ $t(item.banner1_t1) }}</div>
          <div class="textClass">{{ $t(item.banner1_t2) }}</div>
          <div class="toMore" @click="goPages('/introduction')">{{ $t(item.banner1_t3) }}</div>
        </div>
        <div class="banner2Text" v-show="ind == 1">
          <div>{{ $t(item.banner2_t1) }}</div>
          <div class="t2Text">{{ $t(item.banner2_t2) }}</div>
          <div class="cutLine"></div>
          <div class="bannerBox">
            <div class="itemBox"
            @click="toShopPage(item2)"
            v-for="(item2,index2) in item.itemBox"
            :key="index2">
              <img :src="item2.img" alt="">
              {{ $t(item2.title) }}
            </div>
          </div>
        </div> -->
        <img :src="item.img" @click="goProduct(item)" style="cursor: pointer;" />
      </div>
      <!-- </el-carousel-item>
    </el-carousel> -->
    <div class="productCenterBox" id="productCenterBox" ref="productCenterBox">
      <div class="titleBox">
        <div class="zh_title">
          <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
          <span>{{ $t("pages.main.proplan") }}</span>
          <!-- <span >中心</span> -->
          <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        </div>
        <!-- <div class="en_title">{{ $t("pages.main.proplan_desc") }}</div> -->
        <div class="rightBtn">
          <div class="btn next-btn" @click="setSpeed"><i class="el-icon-arrow-left"></i></div>
          <div class="btn prev-btn" @click="setSpeed"><i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div class="shopCardArea">
        <div  class="swiper-container"
        ref="swiperBox"
        >
          <div class="swiper-wrapper">
             <div class="swiper-slide"
             v-for="(item, index) of productList" 
             :key="index"
             @mouseenter="swiperEnter"
             @mouseleave="swiperLeave"
             >
                <shop-card-vue
                :cardWidth="5.3"
                :cardHeight="5.3"
                :dataObj="item"
                ></shop-card-vue>
             </div>
          </div>
        </div>
        <!-- <div class="itemBox wow fadeInUp" v-for="(item, index) of productList" :key="index">
          <div class="left">
            <div>{{ item.title }}</div>
            <div>{{ item.detail }}</div>
            <div @click="goProduct(item)">{{ item.more }}</div>
          </div>
          <div class="right">
            <img :src="item.img" alt="">
          </div>
        </div> -->
      </div>
    </div>

    <div class="briefIntroduction" id="briefIntroduction" ref="briefIntroduction">
      <div class="top">
        <div class="dataCard wow fadeInLeft">
          <div class="item" style="margin-bottom: .6rem">
            <p>
              <span>{{num1}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice1") }}</p>
          </div>
          <div class="item" style="margin-bottom: .6rem">
            <p>
              <span>{{num2}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice2") }}</p>
          </div>
          <div class="item">
            <p>
              <span>{{num3}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice3") }}</p>
          </div>
          <div class="item">
            <p>
              <span>{{num4}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice4") }}</p>
          </div>
          <!-- <div class="blank"></div> -->
        </div>
        <div class="left wow fadeInRight">
          <div class="">
            <div class="title">
              <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
              <span>{{ $t("pages.main.shengli_t1") }}</span> · 
              <span>{{ $t("pages.main.shengli_t2") }}</span>
              <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
            </div>
            <!-- <div class="enTitle">
              Shengli Energy · Green Power
            </div> -->
            <div class="content">
              <p>{{ $t("pages.main.shengli_p1") }}{{ $t("pages.main.shengli_p2") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom wow fadeInUp">
        <img class="bottomImg" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_shenglichangfang.png" alt="">
      </div>
    </div>

    <div class="newsInfo" id="newsInfo">
      <div class="title_zh">
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.newsa") }}</span>
        <!-- <span class="green3ec">资讯</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <!-- <div class="title_en">News and information</div> -->
      <div class="contentsBox wow fadeInUp">
        <div v-for="(item,i) in newsList"
        class="newsBox"
        :key="i">
          <div class="shadowBox">
            <div class="topTime">
            <img class="iconImg" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_shijian.png" alt="">
              {{item.createTime}}
            </div>
            <div class="newsTitle">{{item.title}}</div>
            <div class="centerBtn" @click="goDetail(item.id)">{{ $t("pages.main.learn") }}</div>
          </div>
          <div>
            <img class="coverImg" :src="item.image" alt="">
          </div>
          <div class="tips">
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="cooperativePartner" id="cooperativePartner">
      <div class="title_zh">
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.friend") }}</span>
        <!-- <span>伙伴</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <div class="imgBox  wow fadeInUp">
        <div v-for="(item,i) in componyList"
        :key="i"
        class="logoPic"
        >
          <img :src="item.img" class="logo"/>
        </div>
      </div>
      <!-- <div class="title_en">Company Partner</div> -->
      <!-- <div class="content companyBox">
        <div class="centerBox">200+</div>
        <div v-for="(item,index) in componyList"
        :key="index"
        class="logoBox" 
        :style="{
          'top': item.top + 'rem',
          'left': item.left + 'rem'
        }"
        ref="logoBox"
        > 
          <img :src="item.img" alt="">
        </div>
      </div> -->
    </div>
    
    <div class="article">
      <div class="pageScale">
        <div v-for="(item, i) in pageArr"
        :key="i"
        class="line"
        @click="toArea(item,i)"
        ></div>
        <div :style="{top: pageArr[current].top + 'rem'}"
        class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import shopCardVue from '@/components/shopCard.vue';
 import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import _ from 'lodash'
import { articleTrainingList } from "@/utils/api";
export default {
  name: "PcPage",
  components: {
    shopCardVue
  },
  data() {
    return {
      swiperOpt: null,
      scroll: 0,
      isCollapse: 1,
      swiperList: [
        // {
        //   img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_bannerone2513.png",
        //   url: `/introduction`,
        //   id: 'none',
        //   banner1_t1: "pages.main.banner1_t1",
        //   banner1_t2: "pages.main.banner1_t2",
        //   // banner1_t3: "pages.main.banner1_t3"
        // },
        // {
        //   img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_bannertwo%20(1).png",
        //   url: `/product?type=1`,
        //   id: 9,
        //   banner2_t1: "pages.main.banner2_t1",
        //   banner2_t2: "pages.main.banner2_t2",
        //   itemBox: [
        //     {
        //       title: "pages.main.proplan_t1",
        //       path: '/product?type=1',
        //       id: 9,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_dianxin.png'
        //     },
        //     {
        //       title: "pages.main.proplan_t2",
        //       path: '/product?type=2',
        //       id: 10,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_chuneng.png'
        //     },
        //     {
        //       title: "pages.main.proplan_t3",
        //       path: '/product?type=3',
        //       id: 11,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_dianmo.png'
        //     },
        //     {
        //       title: "pages.main.proplan_t4",
        //       path: '/product?type=4',
        //       id: 12,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_lidain.png'
        //     },
        //     {
        //       title: "pages.main.proplan_t5",
        //       path: '/product?type=7',
        //       id: 19,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_diandongche.png'
        //     },
        //     {
        //       title: null,
        //       path: '/product?type=1',
        //       id: 9,
        //       img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_dian.png'
        //     },
        //   ]
        // },
      ],
      newsList: [],
      nowPage: 0,
      current: 0,
      dataCardArea: 0,
      num1: 20,
      num2: 88000,
      num3: 100,
      num4: 3800,
      componyList: [
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_huawei.png',
          top: 2.15,
          left: 6.23
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_xiaomi.png',
          top: 3.29,
          left: 10.22
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_biyadi.png',
          top: 3.95,
          left: 3.82
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_pingan.png',
          top: 1.2,
          left: 2.9
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_oppo.png',
          top: 0,
          left: 4.96,
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_changhong.png',
          top: 4.53,
          left: 7.72
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_zhongxing.png',
          top: 0.67,
          left: 11.71
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_tailing.png',
          top: 4.47,
          left: 12.65
        },
      ],
      pageArr: [
        {txt: 'topBox',top: .03},
        {txt: 'productCenterBox',top: .25},
        {txt: 'briefIntroduction',top: .47},
        {txt: 'newsInfo',top: .69},
        {txt: 'cooperativePartner',top: .91},
        {txt: 'bottom',top: 1.13},
      ],
      showTips: false
    };
  },
  computed: {
    productList() {
      return [
        {
          title: this.$t("pages.main.proplan_t1"),
          detail: this.$t("pages.main.proplan_t1_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product1.png",
          url: "/product?type=1",
          id: 9
        },
        {
          title: this.$t("pages.main.proplan_t2"),
          detail: this.$t("pages.main.proplan_t2_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product2.png",
          url: "/product?type=2",
          id: 10
        },
        {
          title: this.$t("pages.main.proplan_t3"),
          detail: this.$t("pages.main.proplan_t3_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product3.png",
          url: "/product?type=3",
          id: 11
        },
        {
          title: this.$t("pages.main.proplan_t4"),
          detail: this.$t("pages.main.proplan_t4_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product4.png",
          url: "/product?type=4",
          id: 12
        },
        {
          title: this.$t("pages.main.proplan_t5"),
          detail: this.$t("pages.main.proplan_t5_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product5.png",
          url: "/product?type=7",
          id: 19
        },
        {
          title: this.$t("pages.main.proplan_t6"),
          detail: this.$t("pages.main.proplan_t6_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product6.png",
          url: "/product?type=6",
          id: 18
        }
      ];
    },
    boxList() {
      return [
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_banner.png",
          id: 'box',
          list: [
            {url: '/product?type=3',text: this.$t("pages.main.proplan_t3s"),position: [3.8,3.6]},
            {url: '/product?type=6',text: this.$t("pages.main.proplan_t6s"),position: [6.83,2.86]},
            {url: '/product?type=1',text: this.$t("pages.main.proplan_t1s"),position: [8.66,2.28]},
            {url: '/product?type=7',text: this.$t("pages.main.proplan_t5s"),position: [8.86,5.12]},
            {url: '/product?type=5',text: this.$t("pages.main.proplan_t7s"),position: [12.39,1.89]},
            {url: '/product?type=4',text: this.$t("pages.main.proplan_t4s"),position: [14.88,2.58]},
            {url: '/product?type=2',text: this.$t("pages.main.proplan_t2s"),position: [18.27,5.62],left: true},
          ]
        },
      ]
    }
    // newsInfoLeft() {
    //   let obj = this.$t("pages.news.news" + this.isCollapse);
    //   return [obj.list[0][0]]
    // },
    // newsInfoRight() {
    //   let obj = this.$t("pages.news.news" + this.isCollapse);
    //   return obj.list[0]
    // },
  },
  watch: {
    nowPage: _.debounce(function(oldVal,newVal) {
      if (oldVal > newVal) {
        if (this.current < 6 )this.current++
        if(this.current == 5) {
          window.scrollTo({top: document.body.scrollHeight,behavior: 'smooth'})
          return
        }
        this.$nextTick(()=>{
          let ele = document.getElementById(this.pageArr[this.current].txt)
          ele.scrollIntoView({ behavior: 'smooth',block: 'center' })
        })
      } else {
        if (this.current > 0 )this.current--
        this.$nextTick(()=>{
          let ele = document.getElementById(this.pageArr[this.current].txt)
          ele.scrollIntoView({ behavior: 'smooth',block: 'center' })
        })
      }
    },300)
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
      this.showTips = true
    }, 1000);
    this.getNews()
    this.swiperOpt = new Swiper(this.$refs.swiperBox, {
        loop: true, // 循环模式选项
        // freeMode: true,
        speed: 3000,
        autoplay: {
          delay: 1000,
        },
        centeredSlides: true,
        snapOnRelease: true,
        slidesPerView: 'auto',
        loopedSlides:this.productList.length,  
        navigation: { 
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
        },       
    })
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('wheel', this.wheelScroll, { passive: false });
    // document.body.style.overflow = 'hidden'
    this.dataCardArea = document.querySelector('.dataCard').offsetTop
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto'
    window.removeEventListener('wheel', this.wheelScroll);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toArea(t,i) {
      this.current = i
      if(this.current == 5) {
        window.scrollTo({top: document.body.scrollHeight,behavior: 'smooth'})
        return
      }
      let ele = document.getElementById(t.txt)
      ele.scrollIntoView({ behavior: 'smooth',block: 'center' })
    },
    getNews() {
      articleTrainingList({ 
        page: {
          page: 1,
          per_page: 3,
        },
        language: this.$i18n.locale, 
        state: 0, 
        type: 1,
        articleType:2
      }).then((res) => {
        if (res.code == "000000") {
          this.newsList = res.data.list;
        }
      });
    },
    setSpeed() {
      // this.swiperOpt.speed = 300
      // console.log(this.swiperOpt) 
    },
    swiperEnter() {
      this.swiperOpt.autoplay.stop()
    },
    swiperLeave() {
      this.swiperOpt.autoplay.start()
    },
    toShopPage(obj) {
        this.$router.push({
          path: obj.path,
          query: { id: obj.id },
        });
    },
    goPages(url) {
      this.$router.push({
        path: url,
      });
    },
    goProduct(item) {
      if (item.id == 'none') {
        this.$router.push({
          path: item.url,
        });
      } else {
        this.$router.push({
        path: item.url,
          query: { id: item.id },
        });
      }
    },
    // 去详情
    goDetail(id, url) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          name: '/news?type=1',
          id,
        },
      });
    },
    wheelScroll(event) {
      let arr = [7.4,7.65,7.6,9.62,0]
      event.preventDefault();
      if (event.deltaY > 0) {
        console.log('鼠标向下滚动');
        this.nowPage++
        // console.log(arr[this.nowPage],this.nowPage)
        // this.$nextTick(()=>{
        //   document.documentElement.scrollTop = arr[this.nowPage] + 'rem'
        // })
      } else {
        this.nowPage--
        // this.$nextTick(()=>{
        //   document.documentElement.scrollTop = arr[this.nowPage] + 'rem'
        // })
        // console.log('鼠标向上滚动');
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight
      // console.log(scrollPosition)
      if (this.dataCardArea<scrollPosition) {
        this.sortNum(0,20,'num1')
        this.sortNum(87900,88000,'num2')
        this.sortNum(50,100,'num3')
        this.sortNum(3700,3800,'num4')
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    sortNum(baseNumber, finalNum, str) {
      let step = (finalNum - baseNumber) / 50;//颗粒化可以控制速度
	      let count = baseNumber; // 计数器
	      let initial = 0;
	      let timer = setInterval(() => {
	        count = count + step;
	        if (count >= finalNum) {
	          clearInterval(timer);
	          count = finalNum;
	        }
	        let t = count.toFixed(0);
	        initial = t;
	        this[str] = initial;
	      }, 30);
    }
  },
};
</script>

<style lang="scss" scoped>
.bannerText {
  position: absolute;
  color: #fff;
  font-size: .72rem;
  font-weight: 800;
  font-style: italic;
  line-height: .91rem;
  left: 2.6rem;
  top: 50%;
  transform: translateY(-50%);
  .toMore {
    width: 1.9rem;
    height: .42rem;
    background: #1FCE76;
    transform: skewX(-5deg);
    font-size: .2rem;
    font-weight: 400;
    line-height: .42rem;
    text-align: center;
    margin-top: .94rem;
    cursor: pointer;
  }
  .textClass {
    font-family: Source Han Serif SC;
    font-weight: 600;
    font-size: .9rem;
    color: #0F1C1C;
    line-height: 1.35rem;
    // text-shadow: 4px 4px 0px rgba(255,255,255,0.6);
    font-style: italic;
    background: linear-gradient(0deg, #071616 0%, #1B5548 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: .1rem;
  }
}
.banner2Text {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: .64rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  .t2Text {
    font-size: .24rem;
    font-weight: 400;
    line-height: .33rem;
    margin: .34rem 0 .06rem;
  }
  .cutLine {
    width: .2rem;
    height: .03rem;
    background: #FFFFFF;
  }
  .bannerBox {
    display: flex;
    margin-top: .52rem;
    .itemBox {
      width: 1.74rem;
      height: 1.27rem;
      border: 1px solid #A6AAAA;
      font-weight: 300;
      font-size: .18rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: .55rem;
      text-align: center;
      cursor: pointer;
      img {
        width: .14rem;
        height: .18rem;
        margin: .26rem 0 .30rem;
      }
    }
    .itemBox:hover {
      background: rgba(137, 141, 141,.5);
    }
  }
  .bannerBox > :last-child {
    border: 0;
    img {
      width: auto;
      height: auto;
      margin: auto;
    }
  }
}
.lightBox {
  width: 100%;
  height: 100%;
  position: absolute;
  .dotBox {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    .dot {
      width: .1rem;
      height: .1rem;
      background: #FFF114;
      // border: .1rem solid #4BBA50;
      border-radius: 50%;
      position: relative;
      transform-style: preserve-3d;
      box-sizing: content-box;
    }
    .dot::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: .3rem;
      height: .3rem;
      background: #4BBA50;
      border-radius: 50%;
      transform: translate(-50%, -50%) translateZ(-1px);
      animation: breathe 1s infinite; /* 应用动画 */
    }
    .dot::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: .4rem;
      height: .4rem;
      background: #4BBA50;
      border-radius: 50%;
      transform: translate(-50%, -50%) translateZ(-1px);
      animation: breathe 1s infinite; /* 应用动画 */
    }
    .textBox {
      position: absolute;
      display: block;
      opacity: 0;
      transition: all 1s ease;
      white-space: nowrap;
      font-weight: 400;
      font-size: .18rem;
      color: #FFFFFF;
      background: #4BBA50;
      border-radius: 20px;
      padding: .1rem .28rem;
      left: .40rem;
    }
  }
  @keyframes breathe {
    0% {
      transform: translateZ(-1px) translate(-50%, -50%) scale(1);
      opacity: .4;
    }
    100% {
      transform: translateZ(-1px) translate(-50%, -50%) scale(1.3); /* 涟漪扩散 */
      opacity: 0; /* 逐渐消失 */
    }
  }
  // .dotBox:hover {
  //   .textBox {
  //     // display: block;
  //     opacity: 1;
  //   }
  // }
  .reverse {
    .textBox {
        transform: translateX(-100%);
        left: -.40rem;
    }
  }
}
.article {
  position: fixed;
  bottom: 10%;
  right: 3%;
  z-index: 999;
  .pageScale {
    .line {
      width: .04rem;
      height: .2rem;
      background: #1fce76;
      cursor: pointer;
      margin-bottom: .02rem;
    }
    .bar {
      width: .12rem;
      height: .14rem;
      background: #fff;
      border: 1px solid #1fce76;
      position: absolute;
      left: -.04rem;
      transition: top 1s ease;
      border-radius: .04rem;
    }
  }
}

.productCenterBox {
  width: 100vw;

  .titleBox {
    padding: 0.5rem 0;
    width: 13.66rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    .zh_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.1rem;

      :first-child {
          margin-right: .2rem;
        }

      :last-child {
        // margin-left: .2rem;
      }

      span {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: .4rem;
        color: #1B1B1D;
      }
      
    }

    .en_title {
      text-align: center;
      font-weight: 400;
      font-size: .12rem;
      color: #8C8C8C;
    }
    .rightBtn {
      display: flex;
      .btn {
        width: 32px;
        height: 32px;
        border: 1px solid #E3E3E3;
        font-size: .12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .next-btn {
        margin-right: .14rem;
      }
    }
  }

  .shopCardArea {
    width: 100%;
    // padding: 0 2.6rem;
    display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          width: 5.3rem;
          margin-right: .24rem;
        }
      }
    }
  }

  .contentsBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .itemBox {
      width: 6.92rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.42rem 0;
      background-color: #fafafa;
      margin: 0.1rem;

      .left {
        flex: 1;
        padding: 0 0.44rem 0 0.26rem;

        div {
          &:nth-child(1) {
            font-weight: 600;
            font-size: 0.22rem;
            color: #000000;
          }

          &:nth-child(2) {
            font-weight: 400;
            font-size: 0.14rem;
            color: #8C8C8C;
            margin: 0.12rem 0 0.41rem;
          }

          &:nth-child(3) {
            width: 2.23rem;
            height: 0.43rem;
            line-height: 0.43rem;
            text-align: center;
            background: rgba(25, 204, 65, 0.04);
            border: 0.01rem solid #19CC41;
            font-weight: 400;
            font-size: 0.14rem;
            color: #19CC41;
          }

          &:nth-child(3):hover {
            background-color: #19CC41;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }

      .right {
        width: 2.4rem;
        height: 2.4rem;

        img {
          width: 100%;
          height: 100%;
          transition: 0.5s;
        }

        img:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: 0.5s;
        }
      }
    }
  }
}

.briefIntroduction {
  background: #F7F8FA;
  // display: flex;
  // align-items: flex-start;
  // justify-content: center;
  // padding: .7rem 4.8rem 1.15rem;
  padding-top: 1.09rem;
  margin-top: 0.83rem;
  // background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_lvsedogli%402x.png') no-repeat;
  // background-size: 100% 100%;
  .top {
    width: 13.66rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .dataCard {
      flex: 1;
      flex-shrink: 0;
      // width: 9.58rem;
      // height: 1.83rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      // margin: auto;

      .item {
        display: flex;
        flex-direction: column;
        width: calc(50% - .6rem);
        // align-items: center;

        p {
          &:first-child {
            font-weight: bold;
            font-size: 0.46rem;
            color: #00B52D;
            display: flex;

            span {
              &:first-child {
                font-weight: bold;
                font-size: 0.56rem;
                color: #00B52D;
                line-height: 1;
              }

              &:last-child {
                font-weight: bold;
                font-size: 0.32rem;
                color: #00B52D;
                z-index: 2;
              }
            }
          }

          &:last-child {
            font-weight: 400;
            font-size: 0.18rem;
            color: #717171;
            margin-top: 0.1rem;
            font-family: Microsoft YaHei;
          }
        }
      }

      .blank {
        width: 0.6rem;
        height: 100%;
        position: absolute;
        right: -0.6rem;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: #ffffff;
      }
    }
  }
  .left {
    margin-left: 1.98rem;
    width: 7.44rem;
    // flex: 1;

    .title {
      font-weight: 600;
      font-size: 0.36rem;
      // color: #fff;
      text-align: left;
      margin-bottom: 0.49rem;
      color: #1b1b1b;
    }

    .enTitle {
      font-size: .12rem;
      color: #545D59;
      text-align: center;
      margin: .12rem 0 .38rem;
    }

    .content {
      

      p {
        font-weight: 400;
        font-size: 0.18rem;
        line-height: .32rem;
        color: #1B1B1D;
        text-align: left;
        margin: auto;
        font-family: Microsoft YaHei;
      }
    }

    
  }
  .bottom {
    width: 13.66rem;
    height: 2.8rem;
    margin: auto;
    margin-top: 1.15rem;
    .bottomImg {
      width: 100%;
      height: 100%;
    }
  }
}

.cooperativePartner {
  background: #F7F8FA;
  padding: 1rem 0 .64rem;
  
  .title_zh {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 13.66rem;
    margin: auto;

    :first-child {
          margin-right: .2rem;
        }

      :last-child {
        // margin-left: .2rem;
      }

    span {
      font-weight: 600;
      font-size: 0.4rem;
      color: #30323B;

      // &:last-child {
      //   color: #19CC41;
      // }
    }
  }

  .imgBox {
    width: 13.66rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    .logoPic {
      width: 3.14rem;
      height: 1.6rem;
      background-color: #fff;
      margin-bottom: .36rem;
      .logo {
        width: 100%;
      }
    }
  }

  .title_en {
    text-align: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #8C8C8C;
    margin-top: 0.05rem;
  }

  .companyBox {
    position: relative;
    height: 6.45rem;
  }

  .content {
    padding: 0 2rem;

    .centerBox {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 50%;
      box-shadow: 0px -1px 32px 0px #F1F3F4;
      background: #1FCE76;
      color: #fff;
      font-size: .48rem;
      font-weight: 700;
      text-align: center;
    }
    .logoBox {
      width: 1.92rem;
      height: 1.92rem;
      border-radius: 50%;
      position: absolute;
      animation: bellShow 2s infinite;
      img {
        width: 100%;
      }
    }
    @keyframes bellShow {
            0% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(0, 0, 0, 10%);
            }

            60% {
                transform: scale(1);
                // box-shadow: 0 0 0 5px rgba(31, 206, 118, 0%);
            }

            100% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(31, 206, 118, 0%);
            }
        }

    // img {
    //   width: 100%;
    // }
  }
  
}

.newsInfo {
  padding: 1.39rem 0 1rem;
  // background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/info_bg.png') no-repeat 50% 50%/100%;
  background: #fff;
  width: 13.66rem;
  margin: auto;

  .title_zh {
    display: flex;
    align-items: center;
    // justify-content: center;

      :first-child {
        margin-right: .2rem;
      }

      :last-child {
        // margin-left: .2rem;
      }

    span {
      font-weight: 600;
      font-size: 0.4rem;
      color: #30323B;

      // &:last-child {
      //   color: #19CC41;
      // }
    }
  }

  .title_en {
    text-align: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #8C8C8C;
    margin-top: 0.05rem;
  }

  .contentsBox {
    display: flex;
    align-items: center;
    margin-top: 0.56rem;
    padding-top: .8rem;
    border-top: 1px solid #EDEDED;
    
    .newsBox {
      width: 4.04rem;
      transition: all .5s ease;
      overflow: hidden;
      .shadowBox {
        box-sizing: content-box;
        padding-bottom: .2rem;
        transition: all .5s ease;
      }
      .topTime {
        font-family: Arial;
        font-weight: bold;
        font-size: .18rem;
        color: #1FCE76;
      }
      .iconImg {
        width: .12rem;
        height: .12rem;
        margin-right: .06rem;
      }
      .newsTitle {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: .18rem;
        color: #1B1B1B;
        line-height: .32rem;
        margin: .3rem 0 .55rem;
      }
      .centerBtn {
        width: 1.26rem;
        height: .43rem;
        background: #1FCE76;
        border-radius: .22rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: .18rem;
        color: #FFFFFF;
        cursor: pointer;
      }
      .coverImg {
        width: 4.04rem;
        height: 2.55rem;
        margin: .4rem 0 .15rem;
        transition: all .5s ease;
      }
      .tips {
        height: 2px;
        background: #EDEDED;
        position: relative;
      }
      .line {
        width: 0;
        height: 2px;
        position: absolute;
        background: #1FCE76;
        transition: width .5s ease; 
      }
    }
    .newsBox:hover {
      box-shadow: 2px 2px 15px 2px #EDEDED;
      .shadowBox {
        padding-left: .2rem;
        padding-top: .2rem;
        padding-bottom: 0;
      }
      .line {
        width: 100%;
      }
      .coverImg {
        transform: scale(1.1)
      }
    }
    .newsBox:nth-child(2) {
      margin: 0 .76rem;
    }
  }
  
}

::v-deep .el-carousel__container {
  height: 7.3rem;
}

::v-deep .el-carousel__item {
  height: 7.3rem;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
