<template>
  <div>
    <top-pic :name="name"></top-pic>
    <div class="detail-box" v-if="news && news.id">
      <div class="detail-box-tt wow fadeInUp">
        {{ news.title }}
        <div class="times">
          <span>{{ news.createTime }}</span
          ><span class="viceTitle"></span>{{ news.viceTitle }}
        </div>
      </div>
      <div class="detail-box-item wow fadeInUp">
        <div>
          <div class="detail-box-item-p" v-html="news.articleContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
import { getTrainingById } from "@/utils/api";
export default {
  name: "newsDetail",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/newsDetail",
      newsId: "",
      news: {},
    };
  },
  created() {
    // 顶部图片和当前产品id的设置
    if (this.$route.query && this.$route.query.id && this.$route.query.name) {
      this.name = this.$route.query.name;
      this.newsId = this.$route.query.id;
    }
    this.getDetail();
  },
  methods: {
    getDetail() {
      getTrainingById({ id: this.newsId }).then((res) => {
        if (res.code == "000000") {
          this.news = res.data;
        }
      });
    },
  },
};
</script>

<style>
.detail-box-item-p img {
  width: 100%;
}
</style>

<style scoped lang="scss">
.times {
  font-weight: 500;
  font-size: 0.14rem;
  color: #838484;
  line-height: 0.28rem;
  margin-bottom: 0.2rem;
  .viceTitle {
    display: inline-block;
    height: 0.1rem;
    width: 0.02rem;
    background-color: #838484;
    margin: 0 0.1rem;
  }
}

.detail-box {
  margin: 0.4rem auto;
  // width: 15rem;
  width: 14.4rem;
  overflow: hidden;
  height: auto;
  .detail-box-tt {
    font-size: 0.3rem;
    color: #000;
    line-height: 0.6rem;
    text-align: center;
    font-weight: bold;
   // border-bottom: 1px solid #dedede;
  }
  .detail-box-item {
    color: #333;
    .detail-box-item-tt {
      font-size: 0.16rem;
      line-height: 0.32rem;
      margin-top: 0.4rem;
    }
    .detail-box-item-p {
      font-size: 0.2rem;
      text-indent: 2em;
      line-height: 0.4rem;
    }
    .detail-box-item-text {
      font-size: 0.16rem;
      text-indent: 2em;
      line-height: 0.3rem;
      margin: 0.3rem;
    }
    .detail-box-item-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }
}
// 兼容手机
@media screen and (max-width: 756px) {
  .detail-box {
    margin: 0.4rem auto;
    width: 100%;
    height: auto;
    padding: 0 0.3rem;
    .detail-box-tt {
      font-size: 0.3rem;
      color: #000;
      line-height: 0.8rem;
      text-align: center;
      font-weight: bold;
     // border-bottom: 1px solid #dedede;
    }
    .detail-box-item {
      color: #333;
      .detail-box-item-tt {
        font-size: 0.22rem;
        line-height: 0.42rem;
        margin-top: 0.4rem;
      }
      .detail-box-item-p {
        font-size: 0.22rem;
        text-indent: 2em;
        line-height: 0.4rem;
      }
      .detail-box-item-text {
        font-size: 0.22rem;
        text-indent: 2em;
        line-height: 0.42rem;
        margin: 0.3rem;
      }
      .detail-box-item-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
