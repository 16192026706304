<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_team')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox phoneClass">
      <div class="main_box  wow fadeInUp">
        <div class="items_box">
          <div class="name">
            {{ $t("pages.main.team_name1") }}
          </div>
          <div class="position">
            {{ $t("pages.main.team_position1") }}
          </div>
          <div class="education">
            {{ $t("pages.main.team_education1") }}
          </div>

          <div class="describle">
            {{ $t("pages.main.team_describle1") }}
          </div>
        </div>

        <div class="items_box" style="width: 30%;">
          <div class="name">
            {{ $t("pages.main.team_name2") }}
          </div>
          <div class="position">
            {{ $t("pages.main.team_position2") }}
          </div>
          <div class="education">
            {{ $t("pages.main.team_education2") }}
          </div>

          <div class="describle">
            {{ $t("pages.main.team_describle2") }}
          </div>
        </div>
        <div class="items_box" style="width: 30%;padding-right: 0;">
          <div class="name">
            {{ $t("pages.main.team_name3") }}
          </div>
          <div class="position">
            {{ $t("pages.main.team_position3") }}
          </div>
          <div class="education" style="height: 0.19rem;">
            {{ $t("pages.main.team_education3") }}
          </div>

          <div class="describle">
            {{ $t("pages.main.team_describle3") }}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      page: 1,
      total: 3,
      name: "/team",
    };
  },
  computed: {
    zsList() {
      return [
        {
          name: this.$t("pages.company.zs1"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs1.jpg",
        },
        {
          name: this.$t("pages.company.zs2"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs2.jpg",
        },
        {
          name: this.$t("pages.company.zs3"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs3.jpg",
        },
      ];
    },
  },
  created() {
    console.log(this.$t("pages"));
  },
  methods: {
    goClass() {},
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style scoped lang="scss">
.main_box {
  display: flex;
  margin-bottom: 0.6rem;
  margin-top: .9rem;
  .items_box {
    width: 40%;
    border-left: 0.01rem solid #1fce76;
    padding-left: 0.24rem;
    padding-right: 0.5rem;
    .name {
      font-weight: bold;
      font-size: .2rem;
      color: #262830;
      margin-bottom: 0.1rem;
    }
    .position {
      font-size: .14rem;
      color: #1fce76;
      margin-bottom: 0.06rem;
    }
    .education {
      font-size: .14rem;
      color: #262830;
      margin-bottom: 0.4rem;
    }
    .describle {
      font-size: .14rem;
      color: #838484;
      line-height: .21rem;
    }
  }
}
.company-lc {
  margin: 0.4rem 0;
  div {
    font-size: 0.28rem;
    line-height: 0.3rem;
    margin-top: 0.4rem;
  }
  p {
    font-size: 0.18rem;
    text-indent: 2em;
    line-height: 0.36rem;
  }
}

.image {
  display: block;
  width: 100%;
  // height: 590px;
  object-fit: cover;
  margin-top: .57rem;
  margin-bottom: .6rem;
}
.pagebox {
  display: flex;
  justify-content: center;
  align-content: center;
}
// 兼容手机
@media screen and (max-width: 756px) {
  .main_box {
  display: flex;
  margin-bottom: 0.6rem;
  flex-wrap: wrap;
  .items_box {
    width: 100% !important;
    border-left: 0.01rem solid #1fce76;
    padding-left: 0.24rem;
    padding-right: 0.5rem;
    margin-bottom: .2rem;
    .name {
      font-weight: bold;
      font-size: .24rem;
      color: #262830;
      margin-bottom: 0.1rem;
    }
    .position {
      font-size: .18rem;
      color: #1fce76;
      margin-bottom: 0.06rem;
    }
    .education {
      font-size: .18rem;
      color: #262830;
      margin-bottom: 0.4rem;
    }
    .describle {
      font-size: .18rem;
      color: #838484;
      line-height: .24rem;
    }
  }
}

  .phoneClass {
    overflow: auto;
    .image {
      width: auto;
    }
  }
  .pagebox {
    display: none;
  }
}
</style>
