// common模块里面的base部分(页面)数据
const info = {
  ZH: {
    // 中文
    nav_aboutus: "关于盛利",
    nav_aboutus_intoduction: "盛利集团",
    nav_products_sub: "致力于成为新能源锂电池系统领航者",
    nav_aboutus_course: "发展历程",
    nav_aboutus_certificate: "荣誉证书",
    nav_aboutus_culture: "企业文化",
    nav_aboutus_team: "团队介绍",
    nav_aboutus_service: "服务体系",
    nav_index: "首页",

    nav_products: "解决方案",
    nav_products_cell: "电芯系列",
    nav_products_storage: "储能系列电池",
    nav_products_vehicle: "电摩模组系列",
    nav_products_custom: "定制锂电池",
    nav_products_ddc: "电动车系列",
    nav_products_hdg: "智能换电柜",
    nav_products_daily: "日常用品",

    nav_news: "咨询中心",
    nav_news_sub: "关注盛利集团最新动态，了解产业智能化最新",
    nav_proSubText1: "低电阻，大容量，放电平稳",
    nav_proSubText2: "高效储能，持久稳定",
    nav_proSubText3: "动力强劲，续航持久",
    nav_proSubText4: "高效能量，智能管理",
    nav_proSubText5: "时尚设计，卓越性能",
    nav_proSubText6: "便捷高效，安全可靠",
    nav_proSubText7: "品种多，价格低，质量好",
    nav_contact_sub: "我们会尽快回复您的",
    nav_news_companynews: "新闻资讯",
    nav_news_technical: "技术支持",
    nav_news_problem: "常见问题",

    nav_resources: "人力资源",
    nav_resources_talent: "人才理念",
    nav_resources_social: "社会招聘",
    nav_resources_campus: "校园招聘",
    nav_resources_staff: "员工风采",

    nav_contact: "联系我们",
    nav_contact_contact: "联系方式",
    nav_contact_message: "在线留言",

    foot_compay: "旗下公司",
    foot_compay_name1: "湖南盛利高新能源科技有限公司",
    foot_compay_name2: "湖南盛武新能源科技有限公司",
    foot_compay_name3: "湖南省盛永能源科技有限公司",
    foot_compay_name4: "湖南帅凌科技有限公司",
    foot_compay_name5: "湖南盛远能源科技有限公司",
    foot_compay_name6: "盛利高新能源",
    foot_compay_name7: "盛武新能源",
    foot_compay_name8: "盛利强科技",

    address: "地址: 广东省东莞市常平镇桥沥南门路40号4栋101室",
    shengli: " 盛利集团",
    site: "您的位置",
  },
  EN: {
    // 英文
    nav_aboutus: "Auout Us",
    nav_aboutus_intoduction: "Intoduction",
    nav_aboutus_course: "Course",
    nav_aboutus_certificate: "Certificate",
    nav_aboutus_culture: "Culture",
    nav_index: "index",
    nav_aboutus_team: "Team",
    nav_aboutus_service: "Service",

    nav_products: "Products",
    nav_products_sub: "Committed to become a new energy lithium battery system leader",
    nav_contact_sub: "We will get back to you as soon as possible",
    nav_products_cell: "Cell series",
    nav_products_storage: "Energy storage lithium battery",
    nav_products_vehicle: "Electric vehicle lithium battery",
    nav_products_custom: "Custom lithium battery",
    nav_products_ddc: "Electric vehicle series",
    nav_products_hdg: "Intelligent battery swapping cabinet",
    nav_products_daily: "Daily necessities",

    nav_news: "News",
    nav_news_sub: "Pay attention to the latest developments of Shengli Group and understand the latest industrial intelligence",
    nav_news_companynews: "Company news",
    nav_news_technical: "Technical Support",
    nav_news_problem: "Common problem",
    nav_proSubText1: "Low resistance, large capacity, stable discharge",
    nav_proSubText2: "Efficient energy storage, long-lasting stability",
    nav_proSubText3: "Powerful and long-lasting",
    nav_proSubText4: "Efficient energy and intelligent management",
    nav_proSubText5: "Fashionable design, excellent performance",
    nav_proSubText6: "Convenient and efficient, safe and reliable",
    nav_proSubText7: "Multiple varieties, low prices, and good quality",

    nav_resources: "Human Resources",
    nav_resources_talent: "Talent Concept",
    nav_resources_social: "Social recruitment",
    nav_resources_campus: "Campus Recruiting",
    nav_resources_staff: "Staff style",

    nav_contact: "Contact Us",
    nav_contact_contact: "Contact Details",
    nav_contact_message: "Message",

    foot_compay: "Company",
    foot_compay_name1: "湖南盛利高新能源科技有限公司",
    foot_compay_name2: "湖南盛武新能源科技有限公司",
    foot_compay_name3: "湖南省盛永能源科技有限公司",
    foot_compay_name4: "湖南帅凌科技有限公司",
    foot_compay_name5: "湖南盛远能源科技有限公司",
    foot_compay_name6: "Shengli High-tech Energy",
    foot_compay_name7: "Shengwu New Energy",
    foot_compay_name8: "Shengliqiang Technology",

    address: "Address: Shengli Building, No. 1, Hekang Road, Jiujiang Shui Village, Changping Town, Dongguan City",
    shengli: "SHENGLI ENERGY",
    site: "site",
  },
};
export default info;
