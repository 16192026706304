// pages模块里面的main部分(页面)数据
const info = {
  ZH: {
    // 中文
    banner1_t1: '致力成为',
    banner1_t2: '新能源行业领先锂电公司',
    banner1_t3: '关于盛利',
    banner2_t1: '丰富的产品解决方案',
    banner2_t2: `多种类${'\xa0\xa0'}多方案`,
    search_more: "探索更多",
    shengli: "盛利集团",
    shengli_desc: "致力于成为新能源锂电池系统领航者",
    shengli_t1: "盛利能源",
    shengli_t2: "绿色动力",
    shengli_t3: "三大生产基地",
    base1:'江华生产基地',
    base2:'盛利高新',
    base3:'临武生产基地',
    base4:'盛武新能源',
    base5:'东莞生产基地',
    base6:'盛利能源',
    service_title1:'售前',
    service_title2:'售中',
    service_title3:'售后',
    service_title4:'服务',
    service_pre1:'行业产品技术调查',
    service_pre2:'直面客户产品需求',
    service_pre3:'样品交付技术确认',
    service_pre4:'产品包装技术培训',
    service_in1:'最快交货23天',
    service_in2:'精益计划管理',
    service_in3:'精益流程管理',
    service_in4:'精益质量体系',
    service_after1:'快速解决客户投诉',
    service_after2:'批次跟踪产品信息',
    service_after3:'快速满足新要求',
    service_after4:'定期回访',
    team_name1:'刘高梁',
    team_position1:'创始人 / 集团董事长',
    team_education1:'(清华大学经济管理学硕士)',
    team_describle1:'刘高梁董事长于2007年创办旗下盛利龙电子科技有限公司，在他的带领下经过10年的励精图治，从单一型产品经营到多元化发展，从几十人的小型公司发展到目前拥有控股7家旗下公司，倾注了社会各界朋友的殷切关心和大力支持，而这种关心和支持也不断转化为公司发展壮大的动力之源，同时也成为激励盛利人继往开来、自强不息的精神支柱。“团结、勤奋、诚信、自强、进取”是盛利集团的企业精神,也是对每一个盛利人的勉励在当今激烈的市场竞争下，实现规模与效益同步增长，整体实力与运营效率同步提升，竞争能力与盈利能力同步增强，以客户中心化、企业品牌化、产品高质化、服务快速化、价值最大化为客户争取时间赢得时间，赢得先机。追求，永无止境，我们竭诚期望与社会各界朋友进一步加深沟通与了解、密切合作、共同发展、携手共创新辉煌',
    team_name2:'陈邵勇',
    team_position2:'技术顾问',
    team_education2:'(中南大学电化学博士生导师)',
    team_describle2:'从事各项锂离子新型材料研究、前研性锂电芯产品开发、锂离子电池组散热解决方案等相关工作16年，同时自主拥用6项开发专利，并推广至锂电芯材料、工艺、电池组方面运用。',
    team_name3:'蒋新华',
    team_position3:'锂电芯技术总工',
    team_education3:'',
    team_describle3:'曾在东莞迈科科技、中山天贸科技、深圳龙威盛电子等大型公司，从事各项产品开发、材料研发等相关中高层管理工作11年，对各项锂离子产品有较深的研究，并自主研发3项新型专利。',
    course_txt1:'盛利龙科技成立',
    course_txt2:'专注数码3C电池研发，销售',
    course_txt3:'盛利能源公司成立',
    course_txt4:'专注铝壳电芯研发，销售',
    course_txt5:'盛利驰公司成立',
    course_txt6:'专注锂电保护膜研发，销售',
    course_txt7:'盛利锂能公司成立',
    course_txt8:'专注两轮车电池组开发，销售',
    course_txt9:'湖南盛利高新成立',
    course_txt10:'专注18650三元锂离子电芯研发，销售',
    course_txt11:'湖南盛武新能源成立',
    course_txt12:'专注磷酸铁锂26700及锰酸锂26700开发及生产',
    course_txt13:'东莞智盛科技成立',
    course_txt14:'专注电动两轮车研发，制造',
    course_pc1:'盛利龙科技成立，专注数码3C电池研发、销售',
    course_pc2:'盛利驰公司成立，专注锂电保护膜研发、销售',
    course_pc3:'盛利锂能公司成立，专注两轮车电池组开发销售',
    course_pc4:'盛武新能源成立，专注磷酸铁锂26700及锰酸锂26700开发及生产',
    course_pc5:'盛利能源公司成立，专注铝壳电芯研发、销售',
    course_pc6:'湖南盛利高新成立，专注18650三元锂离子电芯研发、销售',
    course_pc7:'东莞智盛科技成立，专注两轮车研发、制造',


    learn: "了解更多",
    shengli_p1: "盛利集团致力于成为绿色能源领航者，以环保动力电池、风能太阳能储能电池、数码设备电池、电动助力车等为一体的大型实业集团。集团旗下子公司20多家，主导项目遍及全球100多个国家和地区，在海外设有子公司和办事处。",
    shengli_p2: "以研发、生产、销售为一体的新能源科技集团公司。主要产品为锂电池、动力电池；以定制、批发为主；通过诸多国际认证，拥有多项知识产权专利证书；产品远销海外，国内主要和品牌电动车商合作...",
    shengli_notice1: "年自主研发实力",
    shengli_notice2: "亩占地面积",
    shengli_notice3: "研发技术人员",
    shengli_notice4: "工厂工人",
    proplan: "产品中心",
    proplan_desc: "Company Solutions",
    proplan_more: "探索更多 +",
    proplan_t1: "电芯系列",
    proplan_t1s: "电芯解决方案",
    proplan_t1_desc: "高性能电芯系列，卓越能量密度，持久续航，安全可靠，为您的设备提供强大动力支持。",
    proplan_t2: "储能系列电池",
    proplan_t2s: "储能系列电池解决方案",
    proplan_t2_desc: "储能电池系列，高效储能，持久稳定，为绿色能源保驾护航，助力未来可持续发展。",
    proplan_t3: "电摩模组系列",
    proplan_t3s: "电摩模组系列解决方案",
    proplan_t3_desc: "电摩模组系列电池，动力强劲，续航持久，安全可靠，为电动摩托车提供稳定能量支持。",
    proplan_t4: "锂电池系列",
    proplan_t4s: "锂电池系列解决方案",
    proplan_t4_desc: "锂电池系列，高效能量，持久续航，智能管理，为您的设备带来全新动力体验。",
    proplan_t5: "电动车系列",
    proplan_t5s: "电动车系列解决方案",
    proplan_t5_desc: "帅凌电动车系列，时尚设计，卓越性能，高效能锂电池，让您的骑行更加轻松愉悦。",
    proplan_t6: "智能换电柜",
    proplan_t6s: "智能换电柜解决方案",
    proplan_t6_desc: "盛利智行换电柜，便捷高效，安全可靠，为您的电动生活提供无限动力，换电新时代，从此开启。",
    proplan_t7s: "日常用品解决方案",
    friend1: "合作",
    friend2: "伙伴",
    friend: "合作伙伴",
    friend_desc: "研发、生产、销售等成熟生态链",
    newsa: "新闻",
    newsb: "资讯",
    news: "新闻资讯",
    news_desc: "关注盛利集团新闻资讯，了解更多锂电池行业相关知识",
    btnText: "了解详情",
    imgList: [
      {
        title: "18650电芯系列",
        content: "低内阻，大容量，放电平稳",
      },
      {
        title: "银鱼海霸系列",
        content: "动力强劲，超长续航",
      },
      {
        title: "电摩模组系列",
        content: "高容量，超能量",
      },
      {
        title: "储能系列电池",
        content: "兼容性强，支持定制",
      },
    ],
  },
  EN: {
    // 英文
    banner1_t1: 'Strive to be',
    banner1_t2: 'New energy industry leading lithium battery company',
    banner1_t3: 'About SHENGLI',
    banner2_t1: 'Rich product solutions',
    banner2_t2: 'polytype Multi-plan',
    friend1: "cooperation",
    friend2: "buddy",
    search_more: "Explore more",
    shengli: "SHENGLI ENERGY",
    shengli_desc: "Committed to becoming the leader of new energy lithium battery system",
    shengli_t1: "Shengli Energy",
    shengli_t2: "Green Power",
    shengli_t3: "Three major production bases",
    base1:'Jianghua production base',
    base2:'Shengli High-tech',
    base3:'Linwu production base',
    base4:'ShengWu New Energy',
    base5:'Dongguan production base',
    base6:'Shengli Energy',
    service_title1:'pre-sales',
    service_title2:'In sale',
    service_title3:'After sale',
    service_title4:'service',
    service_pre1:'Industry product technology survey',
    service_pre2:'Face customer product needs',
    service_pre3:'Sample delivery technology confirmation',
    service_pre4:'Product packaging technology training',
    service_in1:'The fastest delivery is 23 days',
    service_in2:'Lean plan management',
    service_in3:'Lean process management',
    service_in4:'Lean quality system',
    service_after1:'Quickly resolve customer complaints',
    service_after2:'Batch tracking product information',
    service_after3:'Meet new requirements quickly',
    service_after4:'Regular return visit',
    team_name1:'Liu Gaoliang',
    team_position1:'Founder/Group Chairman',
    team_education1:'(Master of Economics and Management, Tsinghua University)',
    team_describle1:'Chairman Liu Gaoliang founded Shengli Long Electronic Technology Co., Ltd. in 2007, under his leadership after 10 years of hard work, from a single product management to diversified development, from a small company with dozens of people to the current holding of 7 subsidiary companies, poured into the sincere concern and strong support of friends from all walks of life. This kind of care and support has also been transformed into the source of power for the development and growth of the company, and has also become the spiritual pillar that inspires Shengli people to continue to open up and strive for self-improvement. "Unity, diligence, integrity, self-improvement, enterprising" is the spirit of Shengli Group, but also the encouragement of every Shengli people in today is fierce market competition, to achieve scale and efficiency synchronous growth, overall strength and operational efficiency synchronous improvement, competitiveness and profitability synchronous enhancement. Customer-centric, enterprise branding, high quality products, fast service, value maximization to win time for customers to win time, win opportunities. The pursuit is endless. We sincerely look forward to further deepening communication and understanding with friends from all walks of life, close cooperation, common development and joint innovation',
    team_name2:'Chen Shaoyong',
    team_position2:'Technical consultant',
    team_education2:'(Professor of Electrochemistry, Central South University)',
    team_describle2:'Engaged in the research of lithium-ion new materials, pre-research lithium cell product development, lithium-ion battery cooling solutions and other related work for 16 years, while the independent use of 6 development patents, and promoted to lithium cell materials, processes, battery pack applications。',
    team_name3:'Jiang Xinhua',
    team_position3:'Chief engineer of lithium battery technology',
    team_education3:'',
    team_describle3:'He has worked in Dongguan Maike Technology, Zhongshan Tianmao Technology, Shenzhen Longwei Electronics and other large companies, engaged in various product development, material research and development and other related middle and senior management for 11 years, has a deep research on various lithium-ion products, and independently developed 3 new patents。',

    course_txt1:'Shengli Long Technology was established',
    course_txt2:'Focus on digital 3C battery development, sales',
    course_txt3:'Shengli Energy Corporation was established',
    course_txt4:'Focus on aluminum shell cell research and development, sales',
    course_txt5:'Shenglichi company was established',
    course_txt6:'Focus on lithium battery protective film research and development, sales',
    course_txt7:'Shengli Lithium Energy company was established',
    course_txt8:'Focus on the development and sales of two-wheeler battery packs',
    course_txt9:'Hunan Shengli High new establishment',
    course_txt10:'Focus on 18650 ternary lithium ion cell research and development, sales',
    course_txt11:'Hunan Shengwu New Energy was established',
    course_txt12:'Focus on lithium iron phosphate 26700 and lithium manganate 26700 development and production',
    course_txt13:'Dongguan Zhisheng Technology was established',
    course_txt14:'Focus on electric two-wheeler research and development, manufacturing',

    course_pc1:'Shenglilong Technology was established, focusing on digital 3C battery research and development, sales',
    course_pc2:'Shenglichi company was established, focusing on the research and development of lithium battery protective film, sales',
    course_pc3:'Shengli Lithium Energy Company was established, focusing on the development and sales of two-wheeler battery packs',
    course_pc4:'Hunan Shengwu New Energy was established, focusing on the development and production of lithium iron phosphate 26700 and lithium manganate 26700',
    course_pc5:'Shengli Energy Company was established, focusing on the development and sales of aluminum shell cells',
    course_pc6:'Hunan Shengli High-tech was established, focusing on 18650 ternary lithium-ion cell research and development, sales',
    course_pc7:'Dongguan Zhisheng Technology was established, focusing on the development and two-wheeled vehicles',



    learn: "Learn more",
    shengli_p1: "Shengli Group is committed to becoming a leader in green energy, a large-scale industrial group integrating environmentally friendly power batteries, wind energy and solar energy storage batteries, digital equipment batteries, and electric mopeds. The group has more than 20 subsidiaries, leading projects in more than 100 countries and regions around the world, and has subsidiaries and offices overseas.",
    shengli_p2: "A new energy technology group company integrating R\u0026D, production and sales. The main products are lithium batteries and power batteries...",
    shengli_notice1: "years of experience",
    shengli_notice2: "square meters area",
    shengli_notice3: "R&D technicians",
    shengli_notice4: "factory worker",
    proplan: "Product Solution",
    proplan_more: "MORE",
    proplan_t1: "Battery cell series",
    proplan_t1s: "Battery cell series solutions",
    proplan_t1_desc: "High performance battery cell series, excellent energy density, long-lasting endurance, safe and reliable, providing powerful power support for your equipment.",
    proplan_t2: "Energy storage series batteries",
    proplan_t2s: "Energy storage series batteries solutions",
    proplan_t2_desc: "The energy storage battery series provides efficient energy storage and long-lasting stability, safeguarding green energy and supporting sustainable development in the future.",
    proplan_t3: "Electric motorcycle module series",
    proplan_t3s: "Electric motorcycle module series solutions",
    proplan_t3_desc: "The electric motorcycle module series battery has strong power, long endurance, safety and reliability, providing stable energy support for electric motorcycles.",
    proplan_t4: "Lithium battery series",
    proplan_t4s: "Lithium battery series solutions",
    proplan_t4_desc: "Lithium battery series, efficient energy, long-lasting endurance, intelligent management, bring a brand new power experience to your device.",
    proplan_t5: "Electric vehicle series",
    proplan_t5s: "Electric vehicle series solutions",
    proplan_t5_desc: "The electric vehicle series features fashionable design, excellent performance, and high-efficiency lithium batteries, making your riding more relaxed and enjoyable.",
    proplan_t6: "Intelligent battery swapping cabinet",
    proplan_t6s: "Intelligent battery swapping cabinet solutions",
    proplan_t6_desc: "Convenient and efficient, safe and reliable, providing unlimited power for your electric life, a new era of battery swapping begins from now on.",
    proplan_t7s: "Daily necessities solutions",
    friend: "Partner",
    friend_desc: "Mature ecological chain of research and development, production, sales, etc",
    news: "News",
    news_desc: "Follow the news of Shengli Group to learn more about the lithium battery industry",
    btnText: "Learn more",
    imgList: [
      {
        title: "18650 battery cell series",
        content: "Low internal resistance, large capacity, stable discharge",
      },
      {
        title: "Whitebait Haiba Series",
        content: "High capacity, super energy",
      },
      {
        title: "Electric Motorcycle Module Series",
        content: "High capacity, super energy",
      },
      {
        title: "Energy storage battery",
        content: "Strong compatibility, support customization",
      },
    ],
  },
};
export default info;
